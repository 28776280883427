import { desktopContentMinWidthExclusive } from 'layouts/styles';
import { white } from 'now-frontend-shared/themes/colors';

export const burgerMenuPadding = '30px';
const tinyDeviceHeaderPadding = '20px';

export default {
  header: {
    position: 'absolute',
    top: 0,
    padding: '25px',
    zIndex: 2,

    '@media screen and (max-width: 600px)': {
      padding: '15px',
    },
  },
  container: {
    // empty
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',

    '@media screen and (max-width: 980px)': {
      display: 'none',
    },
  },
  button: {
    margin: '0 8px',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  burgerMenu: {
    display: 'none',

    '@media screen and (max-width: 980px)': {
      display: 'block',
    },

    [`@media screen and (max-width: ${desktopContentMinWidthExclusive})`]: {
      position: 'fixed',
      padding: `0 ${burgerMenuPadding}`,
      right: 0,
    },
    // TODO: [DRY][REFACTOR] use a shared constant instead of the literal value `600` here.
    // That way, if we ever want to change what this value is, we can change it in one place and have
    // it update the application everywhere that references this constant.
    '@media screen and (max-width: 600px)': {
      padding: `0 ${tinyDeviceHeaderPadding}`,
    },
  },
  imgLogo: {
    height: '80px',
  },
  linkContainer: {
    margin: '25px 60px 54px 20px',
  },
  link: {
    top: 0,
    right: '100px',
  },
  linkText: {
    fontSize: '15px',
    color: white,
    fontWight: 500,
    fontFamily: 'Roboto',
  },
};
