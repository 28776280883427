import {
  all, fork, take, takeLatest, call, put,
} from 'redux-saga/effects';
import { go } from 'connected-react-router';

// actions
import { getCurrentProperty, makeBet } from '../actions/propertyViewActions';

// api methods
import Api from 'api/properties';

// storage helpers functions
import * as storage from 'now-frontend-shared/utils/storage';

function* ensureGetCurrentProperty({ payload }) {
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    const { data } = yield call(Api.getCurrentProperty, {
      url: `/properties/${payload}`,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    yield put({ type: getCurrentProperty.success, payload: data });
  } catch (err) {
    yield put({ type: getCurrentProperty.failure, err });
  }
}

function* watchGetCurrentProperty() {
  yield takeLatest(getCurrentProperty.type, ensureGetCurrentProperty);
  yield take(getCurrentProperty.success);
}

function* ensureSendBidAmount({
  payload: {
    id,
    amount,
    carryPercentage,
    grantConsideration,
    commission,
    totalCost,
    bidTypeSelection,
    wellAllocationBids,
  },
}) {
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    yield call(Api.makeBet, {
      url: `/properties/${id}/bids`,
      headers: { Authorization: `Bearer ${accessToken}` },
      data: JSON.stringify({
        amount,
        carryPercentage,
        grantConsideration,
        commission,
        totalCost,
        wellAllocationBids,
        bidTypeSelection,
      }),
    });
    yield put({ type: makeBet.success });
    yield put(go(0));
  } catch (err) {
    yield put({ type: makeBet.failure, err });
  }
}

function* watchSendBidAmount() {
  yield takeLatest(makeBet.type, ensureSendBidAmount);
  yield take(makeBet.success);
}

export default function* propertyViewSagas() {
  yield all([fork(watchGetCurrentProperty), fork(watchSendBidAmount)]);
}
