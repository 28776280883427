import { getUserFullName } from 'now-shared/helpers/user-helpers';
import { DateTime } from 'luxon';

/**
 * @typedef {Object} GetUserDisplayNameUser
 * @property {string=} firstName
 * @property {string=} lastName
 * @property {string=} email
 */

/**
 * @param {GetUserDisplayNameUser|null|undefined} user
 * @returns {string}
 */
export function getUserDisplayName(user) {
  return getUserFullName(user) || user.email || 'User';
}

export function canDownloadDocuments(user, property) {
  const propertyEndTime = DateTime.fromISO(property.endTime);
  if (propertyEndTime.toMillis() < DateTime.now().toMillis()) {
    return user.isAdmin
    || user.isViewOnlyListingEditor
    || user.isComplianceSupervisor
    || user.isRegisteredRepresentative;
  }
  return true;
}
