export const ClosingMethod = {
  Escrow: 'escrow',
  AlternativeEscrowAgent: 'alternativeEscrowAgent',
  NoEscrow: 'noEscrow',
};

export const ClosingMethodTitle = {
  [ClosingMethod.Escrow]: 'Escrow',
  [ClosingMethod.AlternativeEscrowAgent]: 'Alternative Escrow Agent',
  [ClosingMethod.NoEscrow]: 'No Escrow',
};
