export default {
  header: {
    marginTop: '8px',
    fontWeight: 'bold',
  },
  description: {
    margin: '8px',
    fontWeight: 'normal',
  },
};
