import theme from 'themes/theme';

export default {
  root: {
    padding: '86px 0',
  },
  title: {
    marginTop: '24px',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '36px',
    textAlign: 'center',
    // TODO: [DRY][REFACTOR] use equivalent theme palette color instead?
    color: '#171721',
  },
  text: {
    fontSize: '14px',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: theme.palette.secondary.text,
    marginTop: '8px',
    maxWidth: '371px',
  },
  homeButton: {
    // TODO: [DRY][REFACTOR] use equivalent theme palette color instead (this is similar to secondary/main but with more transparency)
    color: '#ffffff',
    marginTop: '48px',
    backgroundColor: '#7890A6',
    padding: '15px 33px',
  },
};
