import React, { memo, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Field, formValueSelector, reduxForm,
} from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from 'now-frontend-shared/components/Button';
import { withStyles } from '@material-ui/core/styles';
import CloseModalButton from 'now-frontend-shared/components/CloseModalButton';
import BaseInput from 'now-frontend-shared/components/inputs/BaseInput';
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import { ClosingMethod, ClosingMethodTitle } from 'now-shared/enums/closing-method';
import RequiredDescription from 'now-frontend-shared/components/inputs/RequiredDescription';
import LabelLayout from 'now-frontend-shared/components/inputs/layouts/LabelLayout';
import SelectForIdValue from 'now-frontend-shared/components/Select/SelectForIdValue';
import {
  createFormValidator,
  hasValue,
  maxCharCount,
  minCharCount,
  PERSON_FULL_NAME_MAX_LENGTH,
  PERSON_FULL_NAME_MIN_LENGTH,
  phoneNumber,
  REQUIRED,
  required,
  trimmed,
  validateIsEmail,
  validateNotEmpty,
  validEmailMaxCharCount,
} from 'now-shared/validation/validation-rules';
import FormLayout from 'now-frontend-shared/layouts/FormLayout';
import PhoneInput from 'now-frontend-shared/components/inputs/PhoneInput';
import CheckboxInput from 'now-frontend-shared/components/inputs/CheckboxInput';
import { sendContactUs } from 'store/actions/authActions';
import { ContactUsInquiryTypes } from 'now-shared/enums/contact-us';
import { unicodeCharCount } from 'now-shared/helpers/text-helpers';
import { contactUsMessageMaximumChars } from 'now-shared/validation/contact-us';
import { getCompanyName } from 'now-shared/helpers/company-helpers';
import { humanReadablePhoneNumber } from 'now-shared/helpers/phone-number-helpers';
import styles from './styles';

/**
 * @type {import('./validation-rules').FieldValidators}
 */
const fieldValidators = {
  closingMethod: () => [
    required,
  ],
  bankName: () => [
    required,
    trimmed,
    minCharCount(2),
    maxCharCount(200),
  ],
  agentName: () => [
    required,
    trimmed,
    minCharCount(PERSON_FULL_NAME_MIN_LENGTH),
    maxCharCount(PERSON_FULL_NAME_MAX_LENGTH),
  ],
  agentEmail: () => [
    required,
    trimmed,
    maxCharCount(Math.min(validEmailMaxCharCount, 100)),
    validateIsEmail,
  ],
  agentPhoneNumber: () => [
    required,
    trimmed,
    maxCharCount(20),
    phoneNumber,
  ],
  notesOrComments: (_values, value) => [
    ...hasValue(value) ? [
      validateNotEmpty,
      // Make the max chars for the notes roughly small enough to allow room for the other text fieds
      // we include in the Contact Us message body. If, at submit time, there isn't enough space for
      // the whole message, we'll let the user know how many characters they need to shorten their
      // response by in order to be within the character limit.
      maxCharCount(Math.max(contactUsMessageMaximumChars - 500, 0)),
    ] : [],
  ],
  doesAllowBrokerToContactAgent: () => [
    v => (!v ? REQUIRED : undefined),
  ],
};

const validateForm = createFormValidator(fieldValidators);

const formName = 'selectWinningBid';

const SelectWinningBidModal = ({
  classes,
  handleSubmit,
  onSuccess,
  user,
  bid,
  closingMethod,
  closingMethods,
}) => {
  const { setModal } = useModalSetter();
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    setModal(null);
  };

  // TODO: [UX][BUG] close the modal if the user navigates to a different page using the browser
  // back/forward button while this modal is still open. Currently, it will stay open if the user
  // happens to click the browser back button, for example.

  const onHandleSubmit = async data => {
    const maxTextCharCount = contactUsMessageMaximumChars;
    const textLines = [
      `Listing: ${bid.property.projectName} - LISTING ${bid.property.id}`,
      `Selected Winner: ${getUserFullName(bid.user)} (${getCompanyName(bid.user.company)}) - BID ${bid.id}`,
      `Closing Method: ${closingMethods?.find(cm => cm.id === data.closingMethod).methodName}`,
    ];

    let bankInformationLines = [];

    if (data.closingMethod === ClosingMethod.AlternativeEscrowAgent) {
      bankInformationLines = [
        `Name of Bank: ${data.bankName}`,
        `Name of Agent: ${data.agentName}`,
        `Email of Agent: ${data.agentEmail}`,
        `Phone Number of Agent: ${humanReadablePhoneNumber(data.agentPhoneNumber)}`,
        'Notes or Comments:',
        ...data.notesOrComments ? [
          '',
          data.notesOrComments,
        ] : [],
      ];
    }

    const text = textLines.join('\n');
    const textCharCount = unicodeCharCount(text);
    if (textCharCount > maxTextCharCount) {
      // TODO: [UX] convert this into a MUI modal
      // eslint-disable-next-line no-alert
      window.alert(
        `Maximum character limit exceeded. Please shorten by ${textCharCount - maxTextCharCount} characters`,
      );
      return;
    }

    const contactUsData = {
      inquiryTypeName: ContactUsInquiryTypes.General,
      name: getUserFullName(user),
      email: user.email,
      subject: `Please confirm winner for Listing ${bid.property.id}`,
      text,
      bankInformation: bankInformationLines.join('\n') || undefined,
      phoneNumber: user.phoneNumber,
    };

    await new Promise((resolve, reject) => dispatch(sendContactUs({ data: contactUsData, resolve, reject })));
    onSuccess(data.closingMethod);
  };

  let message;
  const closingMethodName = useMemo(() => closingMethods.find(cm => cm.id === closingMethod)?.methodName, [closingMethods, closingMethod]);

  switch (closingMethodName) {
    case ClosingMethodTitle[ClosingMethod.Escrow]:
      message = 'Please note: an Admin must confirm your selection and will contact you shortly.';
      break;
    case ClosingMethodTitle[ClosingMethod.NoEscrow]:
      message = 'The broker will contact the account manager to further discuss the closing process.';
      break;
    default:
      break;
  }

  return (
    <Grid
      container
      direction="column"
      className={classes.modalWrapper}
      tabIndex={-1}
    >
      <Grid
        container
        direction="column"
        className={classes.contentWrapper}
      >
        <Grid
          item
          container
          direction="column"
          justify="center"
          className={classes.container}
        >
          <Grid
            item
            container
            justify="center"
            className={classes.heading}
          >
            <span>
              {`Are you sure you want to choose ${getUserFullName(bid.user)} (${
                getCompanyName(bid.user.company)
              }) as the winner?`}
            </span>
          </Grid>

          <FormLayout
            onSubmit={handleSubmit(onHandleSubmit)}
          >
            <Grid
              container
              item
              direction="column"
            >
              <Grid container>
                <LabelLayout
                  name="closingMethod"
                  label="Closing Method"
                  isRequired
                >
                  <Field
                    name="closingMethod"
                    component={SelectForIdValue}
                    props={{
                      options: closingMethods.map(({ id, methodName }) => ({
                        id,
                        title: methodName,
                      })).filter(({ id }) => id !== ClosingMethod.AlternativeEscrowAgent),
                      autoFocus: true,
                      'data-cy': 'closingMethodSelect',
                    }}
                  />
                </LabelLayout>
              </Grid>

              {closingMethod === ClosingMethod.AlternativeEscrowAgent && (
                <>
                  <LabelLayout
                    name="bankName"
                    label="Name of Bank"
                    isRequired
                  >
                    <Field
                      component={BaseInput}
                      name="bankName"
                    />
                  </LabelLayout>

                  <LabelLayout
                    name="agentName"
                    label="Name of Agent"
                    isRequired
                  >
                    <Field
                      component={BaseInput}
                      name="agentName"
                    />
                  </LabelLayout>

                  <LabelLayout
                    name="agentEmail"
                    label="Email of Agent"
                    isRequired
                  >
                    <Field
                      component={BaseInput}
                      name="agentEmail"
                    />
                  </LabelLayout>

                  <LabelLayout
                    name="agentPhoneNumber"
                    label="Phone Number of Agent"
                    isRequired
                  >
                    <Field
                      component={PhoneInput}
                      name="agentPhoneNumber"
                    />
                  </LabelLayout>

                  <LabelLayout
                    name="notesOrComments"
                    label="Notes or Comments"
                  >
                    <Field
                      component={BaseInput}
                      name="notesOrComments"
                      props={{
                        placeholder: 'Please enter your notes or comments here',
                        isTextArea: true,
                      }}
                    />
                  </LabelLayout>

                  <LabelLayout
                    name="doesAllowBrokerToContactAgent"
                    label="You allow the broker to contact the escrow agent"
                    isRequired
                  >
                    <Field
                      component={CheckboxInput}
                      name="doesAllowBrokerToContactAgent"
                    />
                  </LabelLayout>
                </>
              )}
              <Grid container justifyContent="left" className={classes.wrapper}>
                <RequiredDescription />
              </Grid>
            </Grid>

            {message && (
              <Grid
                item
                container
                justify="center"
                className={classes.heading}
              >
                <Typography variant="h6" align="center">{message}</Typography>
              </Grid>
            )}

            <Grid container justify="space-around">
              <Button
                label="Cancel"
                // TODO: [DRY] use color palette constant
                // See `frontend-shared/theme/colors.js`
                buttonColor="red"
                onClick={handleCloseModal}
              />
              <Button
                label="Confirm"
                // TODO: [DRY] use color palette constant
                // See `frontend-shared/theme/colors.js`
                buttonColor="green"
                type="submit"
                data-cy="selectWinnerSubmitButton"
              />
            </Grid>
          </FormLayout>
        </Grid>

        <CloseModalButton handleCloseModal={handleCloseModal} />
      </Grid>
    </Grid>
  );
};

SelectWinningBidModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closingMethod: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  email: PropTypes.string,
  // TODO: [TYPE] add missing props
};

SelectWinningBidModal.defaultProps = {
  closingMethod: null,
  onSuccess: undefined,
  email: undefined,
};

const selectFormValue = formValueSelector(formName);

export default compose(
  connect(state => ({
    closingMethod: selectFormValue(state, 'closingMethod'),
    user: state.auth.user,
    closingMethods: state.propertyBidsList.closingMethods,
  }), null, null, { forwardRef: true }),
  reduxForm({
    form: formName,
    validate: validateForm,
    enableReinitialize: true,
  }),
  // TODO: [UX] warn the user about losing unsaved form data when navigating away from page or clicking
  // outside the modal. If the user clicks outside the modal, the modal will close and the form data
  // will be lost instantly.
  withStyles(styles),
  withRouter,
  memo,
)(SelectWinningBidModal);
