import {
  all, call, fork, put, take, takeLatest,
} from 'redux-saga/effects';

// actions
import { getPreSignedUrls } from '../actions/financingFilesActions';

// api methods
import Api from 'api/businessFiles';

function* ensureGetPreSignedUrls({ payload }) {
  const {
    filesName,
    filesMd5,
    filesSize,
    resolve,
    reject,
  } = payload;
  try {
    const { data } = yield call(Api.getPreSignedUrls({ filesName, filesMd5, filesSize }));
    yield put({ type: getPreSignedUrls.success, payload: data });
    if (resolve) {
      resolve(data);
    }
  } catch (err) {
    yield put({ type: getPreSignedUrls.failure, err });
    if (reject) {
      reject(err);
    }
  }
}

function* watchGetPreSignedUrls() {
  yield takeLatest(getPreSignedUrls.type, ensureGetPreSignedUrls);
  yield take(getPreSignedUrls.success);
}

export default function* financingFilesSagas() {
  yield all([fork(watchGetPreSignedUrls)]);
}
