import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import styles from './styles';
import { persistCompanyUpdates, updateCompany } from 'store/actions/companyActions';
import { StyledTextField } from 'components/inputs/styledTextField';
import { validateStockExchangeMic, validateStockTicker } from 'now-frontend-shared/validation/stock-validation';
import { trimmed } from 'now-shared/validation/validation-rules';
import { navigateToNextPage } from 'constants/registrationFlow';
import Onboarding from 'layouts/Onboarding';

function PublicCompanyView({ classes, company, authUser }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [touched, setTouched] = React.useState({
    stockTicker: false,
    stockExchange: false,
  });
  const setTouchedDelay = 0;
  const setTouchedDelayed = (fieldName, newValue) => {
    setTimeout(() => setTouched(prev => ({
      ...prev,
      [fieldName]: newValue,
    })), setTouchedDelay);
  };

  const stockTickerValidation = validateStockTicker(company.stockTicker) || trimmed(company.stockTicker);
  const stockExchangeValidation = validateStockExchangeMic(company.stockExchange) || trimmed(company.stockExchange);
  const verifyStockValues = !!company.stockTicker && !!company.stockExchange && !stockExchangeValidation && !stockTickerValidation;

  const saveCompany = async () => {
    const { isPublicCompany, stockTicker, stockExchange } = company;

    const dataChanged
    = isPublicCompany === authUser.company.isPublicCompany
    && stockTicker === authUser.company.stockTicker
    && stockExchange === authUser.company.stockExchange;

    if (company.companyId && !dataChanged) {
      try {
        await new Promise((resolve, reject) => dispatch(persistCompanyUpdates({
          companyId: company.companyId,
          updates: {
            isPublicCompany,
            stockTicker: isPublicCompany ? stockTicker : null,
            stockExchange: isPublicCompany ? stockExchange : null,
          },
          resolve,
          reject,
        })));
        history.push(navigateToNextPage(history.location.pathname, company.isPublicCompany));
      } catch (err) {
      // eslint-disable-next-line no-console
        console.log(err);
      }
    } else {
      history.push(navigateToNextPage(history.location.pathname, company.isPublicCompany));
    }
  };

  return (
    <Onboarding
      title="Register Account"
      subTitle="Enter your details to proceed further"
      dotNavigationLegend={6}
      nextDisabled={company.isPublicCompany ? !verifyStockValues : false}
      nextClick={saveCompany}
    >
      <Grid
        container
        justifyContent="center"
        direction="column"
        style={{ marginRight: 'auto', maxWidth: '840px' }}
      >
        <Typography variant="h5" component="div" className={classes.subTitle}>
          Are you a publicly traded company?
        </Typography>
        <div className={classes.optionContainer}>
          <Button
            className={company.isPublicCompany === null || company.isPublicCompany === true ? classes.button : classes.buttonSelected}
            data-cy="publiclyTradedNo"
            variant="outlined"
            onClick={() => {
              // TODO: [BUG] await a new Promise() for this async redux action to resolve successfully, so we don't
              // move on from this view if this request fails for connectivity reasons or other reasons.
              const newValue = false;
              dispatch(
                updateCompany({
                  value: newValue,
                  key: 'isPublicCompany',
                }),
              );
              dispatch(
                updateCompany({
                  value: null,
                  key: 'BO25PercentOfCompanyOrMore',
                }),
              );
            }}
          >
            No
          </Button>
          <Button
            className={company.isPublicCompany === null || company.isPublicCompany === false ? classes.button : classes.buttonSelected}
            data-cy="publiclyTradedYes"
            variant="outlined"
            onClick={() => {
              dispatch(
                updateCompany({
                  value: true,
                  key: 'isPublicCompany',
                }),
              );
              dispatch(
                updateCompany({
                  value: false,
                  key: 'BO25PercentOfCompanyOrMore',
                }),
              );
            }}
          >
            Yes
          </Button>
        </div>
        <div className={classes.inputContainer} style={ !company.isPublicCompany ? { opacity: '0.3' } : {}}>
          <Typography
            variant="body1"
            component="div"
            className={classes.subTitle}
          >
            Please add the following information
          </Typography>
          <StyledTextField
            fullWidth
            id="standard-basic"
            className={classes.item}
            label="Stock Symbol"
            data-cy="stockSymbolField"
            required
            disabled={!company.isPublicCompany}
            variant="outlined"
            onFocus={() => setTouchedDelayed('stockTicker', false)}
            onBlur={() => setTouchedDelayed('stockTicker', true)}
            error={!!(touched.stockTicker && stockTickerValidation)}
            helperText={(touched.stockTicker && stockTickerValidation) || '\u00a0'}
            value={company.stockTicker}
            onChange={e => dispatch(
              updateCompany({
                value: e.target.value,
                key: 'stockTicker',
              }),
            )}
          />
          <StyledTextField
            fullWidth
            id="standard-basic"
            className={classes.item}
            data-cy="listedExchangeField"
            label="Listed Exchange"
            disabled={!company.isPublicCompany}
            required
            value={company.stockExchange}
            variant="outlined"
            onFocus={() => setTouchedDelayed('stockExchange', false)}
            onBlur={() => setTouchedDelayed('stockExchange', true)}
            error={!!(touched.stockExchange && stockExchangeValidation)}
            helperText={(touched.stockExchange && stockExchangeValidation) || '\u00a0'}
            onChange={e => dispatch(
              updateCompany({
                value: e.target.value,
                key: 'stockExchange',
              }),
            )}
          />
        </div>
      </Grid>
    </Onboarding>
  );
}

PublicCompanyView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  company: PropTypes.objectOf(PropTypes.string).isRequired,
  authUser: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(
  connect(({ company, auth }) => ({
    company,
    authUser: auth.user,
  })),
  withStyles(styles),
)(PublicCompanyView);
