import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import styles from './styles';
import { persistCompanyUpdates, updateCompany } from 'store/actions/companyActions';
import {
  OptionBestSuitsEntity1, OptionBestSuitsEntity2, OptionBestSuitsEntity1Label, OptionBestSuitsEntity2Label,
} from 'now-shared/enums/company-entities';
import Onboarding from 'layouts/Onboarding';
import { useHistory } from 'react-router-dom';
import { navigateToNextPage } from 'constants/registrationFlow';

function DescriptionView({ classes, company, authUser }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const saveCompany = async () => {
    const { optionBestSuitsEntity1, optionBestSuitsEntity2 } = company;

    const dataChanged
    = optionBestSuitsEntity1 === authUser.company.optionBestSuitsEntity1
    && optionBestSuitsEntity2 === authUser.company.optionBestSuitsEntity2;

    if (company.companyId && !dataChanged) {
      try {
        await new Promise((resolve, reject) => dispatch(persistCompanyUpdates({
          companyId: company.companyId,
          updates: {
            optionBestSuitsEntity1,
            optionBestSuitsEntity2,
          },
          resolve,
          reject,
        })));
        history.push(navigateToNextPage(history.location.pathname));
      } catch (err) {
      // eslint-disable-next-line no-console
        console.log(err);
      }
    } else {
      history.push(navigateToNextPage(history.location.pathname));
    }
  };

  return (
    <Onboarding
      title="Entity Description"
      subTitle="Select option that best suits your entity"
      dotNavigationLegend={5}
      nextDisabled={!company.optionBestSuitsEntity1 && !company.optionBestSuitsEntity2}
      nextClick={saveCompany}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ marginRight: 'auto', maxWidth: '880px', width: '100%' }}
      >
        <RadioGroup
          aria-label="businessType"
          name="row-radio-buttons-group"
          value={company.optionBestSuitsEntity1 || company.optionBestSuitsEntity2}
          onChange={event => {
            const isOption1 = event.target.value === OptionBestSuitsEntity1.CorporateOrLLCOrLessThan50Mil;
            dispatch(
              updateCompany({
                value: null,
                key: isOption1 ? 'optionBestSuitsEntity2' : 'optionBestSuitsEntity1',
              }),
            );
            dispatch(
              updateCompany({
                value: event.target.value,
                key: isOption1 ? 'optionBestSuitsEntity1' : 'optionBestSuitsEntity2',
              }),
            );
          }}
          required
        >
          <FormControlLabel
            className={classes.radio}
            value={OptionBestSuitsEntity2.OtherPerson}
            control={<Radio color="default" data-cy="option1" />}
            label={OptionBestSuitsEntity2Label[OptionBestSuitsEntity2.OtherPerson]}
          />
          <FormControlLabel
            className={classes.radio}
            value={OptionBestSuitsEntity1.CorporateOrLLCOrLessThan50Mil}
            control={<Radio color="default" data-cy="option2" />}
            label={OptionBestSuitsEntity1Label[OptionBestSuitsEntity1.CorporateOrLLCOrLessThan50Mil]}
          />
          <FormControlLabel
            className={classes.radio}
            value={OptionBestSuitsEntity2.BankAssociationInvestmentCompany}
            control={<Radio color="default" data-cy="option3" />}
            label={OptionBestSuitsEntity2Label[OptionBestSuitsEntity2.BankAssociationInvestmentCompany]}
          />
          <FormControlLabel
            className={classes.radio}
            value={OptionBestSuitsEntity2.InvestmentAdvisor}
            control={<Radio color="default" data-cy="option4" />}
            label={OptionBestSuitsEntity2Label[OptionBestSuitsEntity2.InvestmentAdvisor]}
          />
        </RadioGroup>
      </Grid>
    </Onboarding>
  );
}

DescriptionView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  authUser: PropTypes.objectOf(PropTypes.string).isRequired,
  company: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(
  connect(({ company, auth }) => ({
    authUser: auth.user,
    company,
  })),
  withStyles(styles),
)(DescriptionView);
