export default {
  datePicker: {
    height: '42px',
  },
  datePickerStyles: {
    marginTop: '11px',
    marginBottom: '-6px',
    display: 'flex',
    flexDirection: 'column',
  },
  datePickerText: {
    fontSize: '12px',
  },
  infoText: {
    fontSize: '14px',
    padding: '0px 6px',
  },
};
