import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const MyTooltip = props => <Tooltip placement="top" arrow {...props} />;

export const StyledTooltip = withStyles(theme => ({
  tooltip: props => ({
    backgroundColor: props.backgroundColor || theme.palette.primary.contrastText,
    color: props.color || theme.palette.common.black,
    boxShadow: props.boxShadow || theme.shadows[17],
    fontSize: props.fontSize || 13,
    textAlign: 'center',
    maxWidth: props.maxWidth || '300px',
    paddingTop: props.paddingTop || 4,
    paddingBottom: props.paddingBottom || 4,
    paddingLeft: props.paddingLeft || 8,
    paddingRight: props.paddingRight || 8,
    lineHeight: props.lineHeight || '18px',
    letterSpacing: props.letterSpacing || 0.16,
    border: props.border || 'none',
  }),
  arrow: props => ({
    color: props.arrowColor || theme.palette.primary.contrastText,
  }),
}))(MyTooltip);

// This tooltip component by default is triggered by a mouseOver (hover) event.
// The changeToClick boolean prop will change this to be triggered by a click event.
export default function ClickOrHoverTooltip(props) {
  const {
    title,
    changeToClick,
    children,
  } = props;

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipToggle = () => {
    setOpen(!open);
  };

  const childProps = {};

  if (changeToClick) {
    childProps.onClick = handleTooltipToggle;
  } else {
    childProps.onMouseEnter = handleTooltipToggle;
    childProps.onMouseLeave = handleTooltipClose;
  }

  return (
    <StyledTooltip
      arrow
      title={title}
      PopperProps={{
        disablePortal: true,
      }}
      onClose={handleTooltipClose}
      open={open}
      disableFocusListener={!changeToClick}
      disableHoverListener={!changeToClick}
      disableTouchListener={!changeToClick}
      {...props}
    >
      {React.cloneElement(children, childProps)}
    </StyledTooltip>
  );
}

ClickOrHoverTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  changeToClick: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ClickOrHoverTooltip.defaultProps = {
  changeToClick: false,
};
