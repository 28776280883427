const listItemGap = 15;

export default theme => ({
  // Dashboard
  wrapper: {
    paddingTop: '20px',
  },
  noDocumentsText: {
    marginTop: theme.spacing(2),
  },
  // BuyerDashboard
  listItem: {
    border: 'solid #F9A19A 1px',
    borderRadius: '4px',
    width: `calc(50% - ${listItemGap}px)`,
  },
  listItemIcon: {
    justifyContent: 'center',
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: `${listItemGap}px`,
  },
  listItemGrey: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: `${listItemGap}px`,
    background: '#808080',
  },
  listItemTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '25px',
  },
  onBoardingGeneral: {
    background: '#ffffff',
    position: 'absolute',
    borderRadius: '4px',
  },
  switchContainer: {
    marginTop: '25px',
    padding: '0 10px 0',
  },
});
