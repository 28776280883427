import React, { memo } from 'react';
import { Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// components
import Header from 'components/Header';
import Footer from 'components/Footer';

// styled component from material-ui
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';
import { desktopContentMinWidthExclusive, mainPageContentBackgroundColor } from 'layouts/styles';
import { mainContentDiv } from '../../constants';

const isWrapperNewFullWidth = url => {
  const fullWidthWrappedUrls = [
    '/listings',
    '/my-bids',
    '/my-listings',
    '/contact-us',
    '/sign-up',
  ];

  return fullWidthWrappedUrls.includes(url);
};

const WrappedPageInternal = ({ classes, children, location: { pathname: path } }) => (
  <>
    <Header />
    <Box
      display="flex"
      flexDirection="column"
      style={{
        width: '100%',
        minWidth: `${!isWrapperNewFullWidth(path) ? desktopContentMinWidthExclusive : ''}`,
        backgroundColor: mainPageContentBackgroundColor,
        position: 'relative',
      }}
      flexGrow={1}
      id={mainContentDiv}
    >
      <Grid
        component="main"
        container
        direction="column"
        className={isWrapperNewFullWidth(path) ? classes.wrapperNewFullWidth : classes.wrapperNew}
      >
        {children}
      </Grid>
    </Box>
    <Footer />
  </>
);

WrappedPageInternal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export const WrappedPage = compose(
  withStyles(styles),
  withRouter,
  memo,
)(WrappedPageInternal);

const WrappedRoute = ({ component: Component, path }) => (
  <Route
    // TODO: [BUG] this `exact` prop does not seem to function when placed here.
    // The prop must be added directly to the route component that is a child of
    // `<Switch>` (e.g., `<WrappedRoute exact ...>`).
    exact
    path={path}
    render={props => (
      <WrappedPage>
        <Component {...props} />
      </WrappedPage>
    )}
  />
);

WrappedRoute.propTypes = {
  /**
   * TODO: [TYPE] specify accurate type
   */
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
};

export default compose(memo)(WrappedRoute);
