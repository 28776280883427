import businessStyles from '../../styles';
import PhoneInputStyles from 'now-frontend-shared/components/inputs/PhoneInput/styles';

export default {
  ssnOrTinInput: businessStyles.ssnOrTinInput,
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '34px',
    textAlign: 'center',
    letterSpacing: '0.25px',
    color: '#171721',
  },
  subTitle: {
    fontSize: '24px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: '#4B6377',
    margin: '11px 0 25px 0',
  },
  buttonContainer: {
    marginTop: '20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  phoneInput: PhoneInputStyles.phoneInput,
  buttonContainerSingle: {
    marginTop: '20px',
    width: '100%',
    textAlign: 'right',
  },
  button: {
    backgroundColor: '#7890A6',
    color: '#ffffff',
  },
  backButton: {
    color: '#7890A6',
  },
  homeButton: {
    border: '1px solid rgba(178, 157, 121, 0.5)',
    color: '#B29D79',
    marginTop: '24px',
  },
};
