// actions
import * as A from '../actions/settingsActions';

const baseSwitchesValues = {
  confirmingBidsEmails: false,
  outBidsEmails: false,
  closesAuctionEmails: false,
  newListingsEmails: false,
};

const initialState = {
  notificationsSwitches: {
    ...baseSwitchesValues,
  },
  notificationsPending: {
    ...baseSwitchesValues,
  },
  basinsPending: {},
  defaultBasins: [],
  basins: [],
  passwordUpdated: false,
};

export const reducer = {
  settings(state = initialState, action) {
    switch (action.type) {
      case A.setDefaultBasins.type:
        return { ...state, defaultBasins: action.payload };

      case A.setUserNotificationsData.type:
        return { ...state, ...action.payload };

      case A.setNotificationValue.request:
        return { ...state, notificationsPending: { ...state.notificationsPending, ...action.payload } };

      case A.setNotificationValue.success:
      case A.setNotificationValue.failure:
        return {
          ...state,
          notificationsSwitches: { ...state.notificationsSwitches, ...action.payload.notificationsSwitches },
          notificationsPending: { ...state.notificationsPending, ...action.payload.notificationsPending },
        };

      case A.addUserBasin.type:
        return { ...state, basins: [...state.basins, action.payload] };

      case A.removeUserBasin.type:
        return { ...state, basins: state.basins.filter(basin => basin.id !== action.payload.id) };

      case A.setBasinPendingValue.type:
        return { ...state, basinsPending: { ...state.basinsPending, ...action.payload } };

      case A.removeSettingsData.type:
        return initialState;

      case A.updatePassword.success:
        return { ...state, passwordUpdated: true };

      case A.setPasswordIsUpdated.type:
        return { ...state, passwordUpdated: action.payload };

      default:
        return state;
    }
  },
};
