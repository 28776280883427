// actions
import * as A from '../actions/authActions';

// TODO: [REFACTOR][ORGANIZE] move these agreement actions to authActions so that the reducer matches
// the action name.
import * as CompanyAction from '../actions/companyActions';

const initialModalFlags = {
  userSignedUp: false,
  emailSent: false,
  confirmedEmail: null,
  tokensHasExpired: false,
  resetPasswordToken: null,
  passwordChangeConfirmed: false,
  contactUsSended: false,
};

const initialState = {
  authInfo: {
    isAuthorized: null,
    role: null,
    companyId: null,
    userId: null,
    lastEmail: null,
    ...initialModalFlags,
  },
  user: {},
  buyerAgreementTemplate: undefined,
  buyerAgreementTemplateIsLoading: false,
  sellerAgreementTemplate: undefined,
  sellerAgreementTemplateIsLoading: false,
  isSubmittingAgreement: false,
};

export const reducer = {
  auth(state = initialState, action) {
    switch (action.type) {
      case A.signUpCompleted.success:
        return {
          ...state,
          authInfo: {
            // TODO: [BUG?] shouldn't this be state.authInfo?
            ...state.user,
            ...initialModalFlags,
            userId: action.payload.userId,
            userSignedUp: true,
          },
        };

      case A.sendResetPasswordLink.success:
        return {
          ...state,
          authInfo: {
            ...state.authInfo,
            ...initialModalFlags,
            emailSent: true,
          },
        };

      case A.confirmEmail.success:
        return {
          ...state,
          authInfo: {
            ...state.authInfo,
            ...initialModalFlags,
            userId: action.payload.userId,
            confirmedEmail: action.payload.userEmail,
            lastEmail: action.payload.userEmail,
            resetPasswordToken: action.payload.resetPasswordToken,
          },
        };

      case A.removeUserData.type:
        return {
          ...initialState,
          ...!action.payload?.reset && {
            authInfo: {
              ...initialState.authInfo,
              isAuthorized: false,
              ...action.payload?.tokensHasExpired && {
                tokensHasExpired: true,
                lastEmail: state.authInfo.lastEmail,
              },
            },
          },
        };

      case A.changeAuthInfo.type:
        return {
          ...state,
          authInfo: {
            ...state.authInfo,
            ...action.payload,
          },
        };

      case A.setResetPasswordToken.type:
        return {
          ...state,
          authInfo: {
            ...state.authInfo,
            ...initialModalFlags,
            resetPasswordToken: action.payload,
          },
        };

      case A.changePassword.success:
        return {
          ...state,
          authInfo: {
            ...state.authInfo,
            ...initialModalFlags,
            passwordChangeConfirmed: true,
            lastEmail: action.payload.userEmail,
          },
        };

      case A.setUserData.type:
        return {
          ...state,
          user: { ...action.payload },
          authInfo: {
            ...state.authInfo,
            ...initialModalFlags,
            isAuthorized: true,
            lastEmail: action.payload.email,
          },
        };

      case A.clearModalFlags.type:
        return {
          ...state,
          authInfo: {
            ...state.authInfo,
            ...initialModalFlags,
          },
        };

      case A.setIsAuthorized.type:
        return {
          ...state,
          authInfo: {
            ...state.authInfo,
            isAuthorized: action.payload,
          },
        };

      case A.sendContactUs.success:
        return {
          ...state,
          authInfo: {
            ...state.authInfo,
            ...initialModalFlags,
            contactUsSended: true,
          },
        };

      case A.getBuyerAgreementTemplate.type:
        return { ...state, buyerAgreementTemplateIsLoading: true };

      case A.getBuyerAgreementTemplate.failure:
        return { ...state, buyerAgreementTemplateIsLoading: false };

      case A.getBuyerAgreementTemplate.success:
        return {
          ...state,
          buyerAgreementTemplate: action.payload,
          buyerAgreementTemplateIsLoading: false,
        };

      case A.getSellerAgreementTemplate.type:
        return { ...state, sellerAgreementTemplateIsLoading: true };

      case A.getSellerAgreementTemplate.failure:
        return { ...state, sellerAgreementTemplateIsLoading: false };

      case A.getSellerAgreementTemplate.success:
        return {
          ...state,
          sellerAgreementTemplate: action.payload,
          sellerAgreementTemplateIsLoading: false,
        };

      case A.sendBuyerAgreement.type:
        // fall through
      case A.updateBuyerAgreement.type:
        // fall through
      case A.sendSellerAgreement.type:
        // fall through
      case A.updateSellerAgreement.type:
        // fall through
      case CompanyAction.signBuyerAgreement.type:
        // fall through
      case CompanyAction.signSellerAgreement.type:
        // fall through
      case CompanyAction.createFinancialInfo.type:
        return {
          ...state,
          isSubmittingAgreement: true,
        };

      case A.sendBuyerAgreement.success:
        // fall through
      case A.sendBuyerAgreement.failure:
        // fall through
      case A.updateBuyerAgreement.success:
        // fall through
      case A.updateBuyerAgreement.failure:
        // fall through
      case A.sendSellerAgreement.success:
        // fall through
      case A.sendSellerAgreement.failure:
        // fall through
      case A.updateSellerAgreement.success:
        // fall through
      case A.updateSellerAgreement.failure:
        // fall through
      case CompanyAction.signBuyerAgreement.success:
        // fall through
      case CompanyAction.signBuyerAgreement.failure:
        // fall through
      case CompanyAction.signSellerAgreement.success:
        // fall through
      case CompanyAction.signSellerAgreement.failure:
        // fall through
      case CompanyAction.createFinancialInfo.success:
        // fall through
      case CompanyAction.createFinancialInfo.failure:
        return {
          ...state,
          isSubmittingAgreement: false,
        };

      default:
        return state;
    }
  },
};
