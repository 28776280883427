import { HttpStatus } from '../helpers/https-status';

export const EnableDisable = {
  ENABLE: 'enable',
  DISABLE: 'disable',
};

export const BuyerSeller = {
  BUYER: 'buyer',
  SELLER: 'seller',
};

// validations to update, enable or disable, buyer or seller registration

export const validateCompanyEnableTraderRegistration = ({
  editor,
  company,
  enableOrDisable,
  buyerOrSeller,
}) => {
  // validate acceptable parameter values

  if (![EnableDisable.ENABLE, EnableDisable.DISABLE].includes(enableOrDisable)) {
    return {
      message: 'Invalid enableOrDisable value',
      code: HttpStatus.BadRequestException,
    };
  }

  if (![BuyerSeller.BUYER, BuyerSeller.SELLER].includes(buyerOrSeller)) {
    return {
      message: 'Invalid buyerOrSeller value',
      code: HttpStatus.BadRequestException,
    };
  }

  // validate buyer or seller agreements

  const disable = enableOrDisable === EnableDisable.DISABLE;
  const buyerRegistration = buyerOrSeller === BuyerSeller.BUYER;
  const sellerRegistration = buyerOrSeller === BuyerSeller.SELLER;
  // The condition below is TRUE once the agreement status is defined (pending, approved, rejected)
  const sellerAgreementSubmitted = !!editor.company?.sellerAgreement?.status.title;
  const buyerAgreementOrBankSubmitted = !!editor.company?.buyerAgreement?.status.title
  || !!editor.company?.bankInformation?.status.title;

  if (disable && buyerRegistration) {
    if (buyerAgreementOrBankSubmitted) {
      return {
        message: 'Cannot disable registration once either buyer agreement or bank information are submitted',
        code: HttpStatus.UnprocessableEntity,
      };
    }

    if (!company.enableSellerRegistration) {
      return {
        message: 'Cannot disable buyer registration if seller registration is already disabled',
        code: HttpStatus.UnprocessableEntity,
      };
    }
  }

  if (disable && sellerRegistration) {
    if (sellerAgreementSubmitted) {
      return {
        message: 'Cannot disable registration once seller agreement is submitted',
        code: HttpStatus.UnprocessableEntity,
      };
    }

    if (!company.enableBuyerRegistration) {
      return {
        message: 'Cannot disable seller registration if buyer registration is already disabled',
        code: HttpStatus.UnprocessableEntity,
      };
    }
  }

  return undefined;
};
