export default {
  wrapper: {
    borderRadius: '10px',
    width: '100%',
    height: '100%',
    background: 'hsla(0, 0%, 0%, 0.5)',
    position: 'absolute',
    zIndex: '999',
  },
  container: {
    height: '683px',
    width: '784px',
    background: '#ffffff',
    margin: '100px auto',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '20px',
    gap: '20px',
  },
  title: {
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '0.25px',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '34px',
  },
  description: {
    fontSize: '20px',
    letterSpacing: '0.15px',
    color: '#000000',
  },
  icon: {
    height: '40px',
    width: '40px',
    alignSelf: 'flex-end',
    cursor: 'pointer',
  },
  handShake: {
    width: '254px',
    height: '187px',
    alignSelf: 'center',
  },
  button: {
    background: '#7890A6',
    boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    color: '#ffffff',
    padding: '16px 34px',
    width: 'fit-content',
    alignSelf: 'center',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#7890A6',
      opacity: '0.8',
      transition: '0.3s',
    },
  },
};
