import { landingPageLargeText } from 'now-frontend-shared/themes/colors';
import '@fontsource/archivo-black';

export default {
  wrapper: {
    position: 'relative',
    height: '100vh',
    overflow: 'hidden',
  },
  videoContainer: {
    position: 'absolute',
    overflow: 'hidden',
  },
  video: {
    minHeight: '100vh',
    minWidth: '100vw',
    transform: 'scaleX(-1)',
    'object-fit': 'cover',

    '@media screen and (max-width: 1100px)': {
      transform: 'translateX(20%)',
      minWidth: 'unset',
    },

    '@media screen and (max-width: 600px)': {
      minHeight: 'unset',
      height: '100vh',
      transform: 'translateX(18%)',
    },

    '@media (aspect-ratio: 256/135)': {
      width: '100vw',
    },
  },
  buttonContainer: {
    display: 'flex',

    '@media screen and (max-width: 980px)': {
      display: 'none',
    },
  },
  middleButtonContainer: {
    display: 'flex',
    gap: '35px',

    '@media screen and (max-width: 680px)': {
      gap: '10px',
      flexDirection: 'column',
    },
  },
  button: {
    width: '180px',
  },
  scrollDownIcon: {
    padding: '15px 0 25px',
    zIndex: 2,
    position: 'absolute',
    bottom: '20px',

    '& svg': {
      cursor: 'pointer',
    },

    '@media screen and (max-width: 600px)': {
      display: 'none',
    },
  },
  descriptionContainer: {
    maxWidth: '760px',
    marginTop: '200px',
    fontWeight: 400,
    fontSize: '48px',
    lineHeight: '58px',
    color: landingPageLargeText,
    textAlign: 'justify',
    zIndex: 2,
    marginLeft: 'auto',
    marginRight: '100px',
    padding: '20px',

    '@media screen and (max-width: 1280px)': {
      fontSize: '44px',
      lineHeight: '54px',
    },

    '@media screen and (max-width: 1024px)': {
      fontSize: '40px',
      lineHeight: '50px',
    },

    '@media screen and (max-width: 960px)': {
      fontSize: '36px',
      lineHeight: '42px',
    },

    '@media screen and (max-width: 600px)': {
      fontSize: '28px',
      lineHeight: '36px',
      fontWeight: 400,
      alignItems: 'center',
      textAlign: 'center',
      gap: '15px',
    },

    '@media screen and (max-width: 360px)': {
      fontSize: '26px',
      lineHeight: '30px',
    },

    '@media screen and (max-width: 320px)': {
      fontSize: '23px',
      lineHeight: '30px',
    },
  },
  descriptionTextContainer: {
    width: '300px',
  },
  descriptionText: {
    fontSize: '24px',
    fontWeight: 'normal',
    lineHeight: 1.5,
    '@media screen and (max-width: 600px)': {
      fontSize: '20px',
    },
  },
  noWrap: {
    fontFamily: 'Archivo Black',
  },
};
