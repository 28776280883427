import {
  all, fork, takeLatest, take, put, select, call,
} from 'redux-saga/effects';

// auth actions and sagas
import {
  getSellerAgreementTemplate,
  sendSellerAgreement,
  updateSellerAgreement,
} from 'store/actions/authActions';
import { getUserDataFromAccessToken } from 'store/sagas/auth';

// api
import Api from 'api/user';

// storage helpers functions
import * as storage from 'now-frontend-shared/utils/storage';

function* ensureGetSellerAgreementTemplate({ payload }) {
  let resolve;
  let reject;
  if (payload) {
    ({ resolve, reject } = payload);
  }
  try {
    const { data } = yield call(Api.getSellerAgreementTemplate);
    yield put({
      type: getSellerAgreementTemplate.success,
      payload: {
        document: data.content,
        version: data.updatedAt,
      },
    });
    if (resolve) {
      resolve(data);
    }
  } catch (err) {
    yield put({
      type: getSellerAgreementTemplate.failure,
      err,
    });
    if (reject) {
      reject(err);
    }
  }
}

function* watchGetSellerAgreementTemplate() {
  yield takeLatest(getSellerAgreementTemplate.type, ensureGetSellerAgreementTemplate);
  yield take(getSellerAgreementTemplate.success);
}

function* ensureSendSellerAgreement({ payload }) {
  const {
    resolve,
    reject,
    ...payloadRest
  } = payload;
  const companyId = yield select(({ auth }) => auth.user.companyId);
  try {
    if (!companyId) {
      const e = new Error();
      e.response = {
        data: {
          message: "You don't have a company",
        },
      };
      throw e;
    }
    yield call(Api.sendSellerAgreement(companyId), { data: JSON.stringify(payloadRest) });
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    const isAuthorized = yield select(({ auth }) => auth.authInfo.isAuthorized);
    if (isAuthorized) yield call(getUserDataFromAccessToken, { accessToken });
    yield put({ type: sendSellerAgreement.success });
    if (resolve) {
      resolve();
    }
  } catch (err) {
    yield put({
      type: sendSellerAgreement.failure,
      err,
    });
    if (reject) {
      reject(err);
    }
  }
}

function* watchSendingSellerAgreement() {
  yield takeLatest(sendSellerAgreement.type, ensureSendSellerAgreement);
  yield take(sendSellerAgreement.success);
}

function* ensureUpdateSellerAgreement({ payload }) {
  const {
    resolve,
    reject,
    ...payloadRest
  } = payload;
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    yield call(Api.updateSellerAgreement, {
      data: payloadRest,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    yield call(getUserDataFromAccessToken, { accessToken });
    yield put({ type: updateSellerAgreement.success });
    if (resolve) {
      resolve();
    }
  } catch (err) {
    yield put({ type: updateSellerAgreement.failure, err });
    if (reject) {
      reject();
    }
  }
}

function* watchUpdateSellerAgreement() {
  yield takeLatest(updateSellerAgreement.type, ensureUpdateSellerAgreement);
  yield take(updateSellerAgreement.success);
}

export default function* sellerAgreementSagas() {
  yield all([
    fork(watchSendingSellerAgreement),
    fork(watchGetSellerAgreementTemplate),
    fork(watchUpdateSellerAgreement),
  ]);
}
