import queryString from 'query-string';
import { makeAxiosRequest } from 'utils/apiMethods';

export default {
  getPreSignedUrls: ({
    filesName,
    filesMd5,
    filesSize,
  }) => {
    const params = queryString.stringify({
      filesName,
      filesMd5,
      filesSize,
    }, { arrayFormat: 'bracket' });

    return makeAxiosRequest(
      `/get-s3-pre-signed-urls-for-company-uploads?${params}`,
      {
        method: 'get',
      },
      true,
    );
  },
};
