import {
  all,
  select,
  fork,
  take,
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

// actions
import { updateUserInfo } from 'store/actions/userActions';

// api methods
import Api from 'api/user';

import { getUserDataFromAccessToken } from './auth';
import { getStorageItem } from 'now-frontend-shared/utils/storage';
import { formattedPhoneToNumberString } from 'now-frontend-shared/utils/helpers';

function* ensureUpdateUserInfo({ payload }) {
  const actualPayload = payload ?? {};
  const { resolve, reject } = actualPayload;
  try {
    const { companyId, ...userState } = yield select(({ user }) => user);
    const authUser = yield select(({ auth }) => auth.user);
    const { isAuthorized } = yield select(({ auth }) => auth.authInfo);
    const accessToken = JSON.parse(getStorageItem('accessToken', '{}'));
    const userData = userState.firstName ? userState : authUser;
    const {
      firstName,
      lastName,
      department,
      jobTitle,
      phoneNumber,
      isAccountManager,
    } = userData;
    yield call(Api.updateUserInformation, {
      url: `${process.env.REACT_APP_API_URL}/users/${authUser.id}`,
      data: JSON.stringify({
        firstName,
        lastName,
        department,
        jobTitle,
        isAccountManager: userState?.isAccountManager || isAccountManager || false,
        phoneNumber: phoneNumber ? formattedPhoneToNumberString(phoneNumber) : null,
        companyId: companyId || authUser.companyId,
      }),
    });
    if (isAuthorized) yield call(getUserDataFromAccessToken, { accessToken });
    yield put({ type: updateUserInfo.success });
    if (resolve) {
      resolve();
    }
  } catch (err) {
    yield put({ type: updateUserInfo.failure, err });
    if (reject) {
      reject();
    }
  }
}

function* watchAllUserInfo() {
  yield takeLatest(updateUserInfo.type, ensureUpdateUserInfo);
  yield take(updateUserInfo.success);
}

export default function* userSagas() {
  yield all([fork(watchAllUserInfo)]);
}
