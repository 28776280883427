// components
import BaseInput from 'now-frontend-shared/components/inputs/BaseInput';
import PhoneInput from 'now-frontend-shared/components/inputs/PhoneInput';

export const updateAccountFields = [
  {
    name: 'firstName',
    layoutProps: {
      label: 'First Name',
      isRequired: true,
    },
    inputProps: {
      component: BaseInput,
      props: {
        placeholder: 'Bill',
        disabled: true,
      },
    },
  },
  {
    name: 'lastName',
    layoutProps: {
      label: 'Last Name',
      isRequired: true,
    },
    inputProps: {
      component: BaseInput,
      props: {
        placeholder: 'Fergusson',
        disabled: true,
      },
    },
  },
  {
    name: 'company',
    layoutProps: {
      label: 'Company',
      isRequired: false,
    },
    inputProps: {
      component: BaseInput,
      props: {
        disabled: true,
        placeholder: 'Sell Company Inc',
      },
    },
  },
  {
    name: 'department',
    layoutProps: {
      label: 'Department',
      isRequired: true,
    },
    inputProps: {
      component: BaseInput,
      props: {
        placeholder: 'Selling and marketing department',
        disabled: true,
      },
    },
  },
  {
    name: 'jobTitle',
    layoutProps: {
      label: 'Job Title',
      isRequired: true,
    },
    inputProps: {
      component: BaseInput,
      props: {
        placeholder: 'Head of Selling Managers',
        disabled: true,
      },
    },
  },
  {
    name: 'phoneNumber',
    layoutProps: {
      label: 'Phone Number',
      isRequired: true,
    },
    inputProps: {
      component: PhoneInput,
      disabled: true,
    },
  },
  {
    name: 'email',
    layoutProps: {
      label: 'E-mail',
      isRequired: true,
    },
    inputProps: {
      component: BaseInput,
      props: {
        disabled: true,
      },
    },
  },
];
