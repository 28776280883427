import React, { useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { StyledTextField } from 'components/inputs/styledTextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import styles from './styles';
import { persistCompanyUpdates, updateCompany } from 'store/actions/companyActions';
import { getAllStates } from 'store/actions/statesActions';
import Onboarding from 'layouts/Onboarding';
import { useHistory } from 'react-router-dom';
import { navigateToNextPage } from 'constants/registrationFlow';

function Business({
  company,
  states,
  authUser,
  user,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  React.useEffect(() => {
    dispatch(getAllStates());
  }, [dispatch]);

  React.useEffect(() => {
    if (typeof company.stateId === 'number' && states?.states?.length) {
      dispatch(updateCompany({ value: states.states.find(state => state.id === company.stateId), key: 'stateId' }));
    }
  }, [dispatch, company.stateId, states.states]);

  const companyRef = useRef();
  companyRef.current = company;

  const userRef = useRef();
  userRef.current = user;

  const authUserRef = useRef();
  authUserRef.current = authUser;

  const businessFields = [
    { label: 'Address', value: 'businessStreetAddress', dataCy: 'businessStreetAddress' },
    { label: 'Address (optional)', value: 'businessStreetAddress2', dataCy: 'businessStreetAddress2' },
  ];
  const createFields = (list, autoFocus) => (
    <>
      {list.map(({ label, value, dataCy }, i) => (
        <StyledTextField
          value={company[value]}
          key={label}
          variant="outlined"
          onChange={e => {
            dispatch(updateCompany({ value: e.target.value, key: value }));
          }}
          id="standard-full-width"
          label={label}
          data-cy={dataCy}
          placeholder={label}
          fullWidth
          margin="normal"
          autoFocus={!!autoFocus && i === 0}
        />
      ))}
    </>
  );

  const requiredCompany = {
    businessStreetAddress: company.businessStreetAddress,
    city: company.city,
    zipCode: company.zipCode,
    stateId: company.stateId,
  };

  const areBusinessFieldsValid
    = Object.values(requiredCompany).every(v => {
      if (!v) return false;
      if (typeof v === 'object' || v.length > 0) return true;
      return false;
    });

  const saveCompany = async () => {
    const {
      businessStreetAddress, businessStreetAddress2, city, zipCode, stateId,
    } = company;

    const dataHasChanged
        = businessStreetAddress === authUser.company.businessStreetAddress
        && businessStreetAddress2 === authUser.company.businessStreetAddress2
        && city === authUser.company.city
        && zipCode === authUser.company.zipCode
        && stateId.id === authUser.company.stateId;

    if (company.companyId && !dataHasChanged) {
      try {
        await new Promise((resolve, reject) => dispatch(persistCompanyUpdates({
          companyId: company.companyId,
          updates: {
            businessStreetAddress,
            businessStreetAddress2,
            city,
            zipCode,
            stateId,
          },
          resolve,
          reject,
        })));
        history.push(navigateToNextPage(history.location.pathname));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    } else {
      history.push(navigateToNextPage(history.location.pathname));
    }
  };

  return (
    <Onboarding
      title="Business Address"
      subTitle="Input business address of the entity that will transact"
      dotNavigationLegend={4}
      nextDisabled={!areBusinessFieldsValid}
      nextClick={saveCompany}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ marginRight: 'auto', maxWidth: '840px', width: '100%' }}
      >
        {createFields(businessFields)}
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <StyledTextField
              value={company.city}
              key="city"
              variant="outlined"
              onChange={e => {
                dispatch(updateCompany({ value: e.target.value, key: 'city' }));
              }}
              id="standard-full-width"
              label="City"
              data-cy="city"
              placeholder="City"
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              value={company.zipCode}
              key="zip"
              variant="outlined"
              onChange={e => {
                dispatch(
                  updateCompany({ value: e.target.value, key: 'zipCode' }),
                );
              }}
              id="standard-full-width"
              label="Zip"
              data-cy="zip"
              placeholder="Zip"
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              margin="normal"
              fullWidth
              value={company.stateId}
              onChange={(_, newValue) => {
                dispatch(updateCompany({ value: newValue, key: 'stateId' }));
              }}
              id="state-select"
              data-cy="state"
              options={states.states}
              style={{
                maxWidth: 629,
                marginTop: '16px',
                marginBottom: '8px',
              }}
              getOptionLabel={option => option.title || ''}
              renderInput={params => (
                <StyledTextField {...params} label="State" variant="outlined" />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Onboarding>
  );
}

Business.propTypes = {
  company: PropTypes.objectOf(PropTypes.any).isRequired,
  states: PropTypes.objectOf(PropTypes.any).isRequired,
  authUser: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default compose(
  connect(({
    company, states, auth, user,
  }) => ({
    authUser: auth.user,
    company,
    states,
    user,
  })),
  withStyles(styles),
)(Business);
