import {
  all, fork, take, takeLatest, call, put,
} from 'redux-saga/effects';

// actions
import { getPropertyBids, setListingWinner } from '../actions/propertyBidsListActions';

// api methods
import Api from 'api/bids';
import PropertiesApi from 'api/properties';

// storage helpers functions
import * as storage from 'now-frontend-shared/utils/storage';

import { SortDirection } from 'now-shared/enums/sort-direction';

function* ensureGetPropertyBidsList({ payload: { id, query } }) {
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    const {
      commonInfo: { data: commonInfo },
      bidsList: { data: bidsList },
      closingMethods: { data: closingMethods },
    } = yield all({
      commonInfo: yield call(PropertiesApi.getCurrentProperty, {
        url: `/properties/${id}`,
        headers: { Authorization: `Bearer ${accessToken}` },
      }),
      bidsList: yield call(Api.getPropertyBids, {
        url: query.includes('order')
          ? `/properties/${id}/bids${query}&limit=5`
          : `/properties/${id}/bids${query}&limit=5&order=createdAt&sort=${SortDirection.Descending}`,
        headers: { Authorization: `Bearer ${accessToken}` },
      }),
      closingMethods: yield call(PropertiesApi.getClosingMethods, {
        url: '/closing-methods',
        headers: { Authorization: `Bearer ${accessToken}` },
      }),
    });
    yield put({ type: getPropertyBids.success, payload: { commonInfo, bidsList, closingMethods } });
  } catch (err) {
    yield put({ type: getPropertyBids.failure, err });
  }
}

function* watchGetPropertyBidsList() {
  yield takeLatest(getPropertyBids.type, ensureGetPropertyBidsList);
  yield take(getPropertyBids.success);
}

function* ensureSetListingWinner({
  payload: {
    listingId,
    selectedWinnerBidId,
    closingMethodId,
    query,
    resolve,
    reject,
  },
}) {
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    yield call(Api.setListingWinner, {
      url: `/properties/${listingId}/selected-winner`,
      headers: { Authorization: `Bearer ${accessToken}` },
      data: JSON.stringify({
        selectedWinnerBidId,
        closingMethodId,
      }),
    });
    yield put({ type: setListingWinner.success });
    if (resolve) {
      resolve();
    }
    yield put({ type: getPropertyBids.type, payload: { id: listingId, query } });
  } catch (err) {
    yield put({ type: setListingWinner.failure, err });
    if (reject) {
      reject(err);
    }
  }
}

function* watchSetListingWinner() {
  yield takeLatest(setListingWinner.type, ensureSetListingWinner);
  yield take(setListingWinner.success);
}

export default function* propertyBidsListSagas() {
  yield all([
    fork(watchGetPropertyBidsList),
    fork(watchSetListingWinner),
  ]);
}
