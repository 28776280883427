import { landingPageLargeText } from 'now-frontend-shared/themes/colors';

export default {
  singleBioContainer: {
    position: 'relative',
    backgroundColor: 'rgba(255, 255, 255, 0.88)',
    opacity: 0.9,
  },
  bioCircle: {
    width: '249px',
    height: '249px',
    borderRadius: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'absolute',
    top: '-124px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  bioSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    paddingTop: '142px',
  },
  bioAccordion: {
    width: '100%',
    height: '100%',
    background: 'none',
    boxShadow: 'none',

    '&.Mui-expanded': {
      margin: '0px',
      paddingBottom: '50px',
      height: '551px',
      transition: 'all 146ms',
      '@media screen and (max-width: 1100px)': {
        height: 'inherit',
      },
    },
  },
  teamMemberName: {
    fontFamily: 'Roboto',
    fontSize: '34px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: landingPageLargeText,
    zIndex: 3,
    marginLeft: 'auto',
    marginRight: 'auto',
    top: '141px',
  },
  viewMoreIcon: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  bioText: {
    fontSize: '20px',
    '@media screen and (max-width: 1400px)': {
      fontSize: '16px',
    },
  },
  contactInfo: {
    textAlign: 'center',
    marginTop: '20px',
  },
};
