import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { StyledTextField } from 'components/inputs/styledTextField';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import styles from './styles';
import { createFinancialInfo, saveFileUpload } from 'store/actions/companyActions';
import PhoneInput from 'now-frontend-shared/components/inputs/PhoneInput';
import AmountInput from 'now-frontend-shared/components/inputs/AmountInput';
import { generalRules } from 'now-shared/validation/validation-rules';
import validateBidAllowance from 'now-frontend-shared/validation/validateBidAllowance';
import { COMPANY_DASHBOARD } from 'constants/registrationFlow';
import Onboarding from 'layouts/Onboarding';
import { documentTypes } from 'now-shared/helpers/document-types';
import { acceptFileFormats } from 'now-shared/validation/listing-validation';
import DropZone, { dropZoneTypes } from 'now-frontend-shared/components/DropZone';
import {
  getPreSignedUrls,
  removeAWSDataFile,
  setAWSData,
  setUnloadedFilesExist,
} from 'store/actions/financingFilesActions';

function FinancingInformationView({
  classes,
  AWSData,
  preSignedUrls,
  authUser,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [financial, setFinancial] = useState({
    bankName: '',
    bankerName: '',
    bankerPhoneNumber: '',
    requestedBidAllowance: '',
    notes: '',
  });
  const [touchedEmail, setTouchedEmail] = useState(false);
  const [touchedPhone, setTouchedPhone] = useState(false);
  const [touchedAllowance, setTouchedAllowance] = useState(false);
  const [terms, setTerms] = useState(false);
  const [dropZoneIsProcessingFile, setDropZoneIsProcessingFile] = useState(false);
  const [dropZoneHasFileNotUploaded, setDropZoneHasFileNotUploaded] = useState(false);

  const uploadIsPending = dropZoneIsProcessingFile || dropZoneHasFileNotUploaded;
  const hasStoredBankInfo = !!authUser.company?.bankInformation?.bankName;

  useEffect(() => {
    dispatch(setUnloadedFilesExist(uploadIsPending));
  }, [dispatch, uploadIsPending]);

  const financingDocumentsStored = useMemo(() => (
    (authUser.company?.documents ?? []).filter(
      doc => doc.type === documentTypes.AVAILABLE_LIQUIDITY,
    )
  ), [authUser]);

  const hasFinancingDocumentStored = !!financingDocumentsStored.length;

  useEffect(() => {
    if (hasStoredBankInfo) {
      setFinancial({
        bankName: authUser.company.bankInformation.bankName,
        bankerName: authUser.company.bankInformation.bankerName,
        bankerEmail: authUser.company.bankInformation.bankerEmail,
        bankerPhoneNumber: authUser.company.bankInformation.bankerPhoneNumber,
        requestedBidAllowance: authUser.company.bankInformation.requestedBidAllowance,
        notes: authUser.company.bankInformation.notes,
      });
    }
  }, [authUser, hasStoredBankInfo]);

  const financialFields = [
    { label: 'Name of Bank or Financial Institution', value: 'bankName' },
    { label: 'Name of Banker or Private Equity Contact', value: 'bankerName' },
  ];
  const financialFields2 = [{ label: 'Notes/Instructions', value: 'notes' }];
  const createFields = (list, func, state, autoFocus = false) => (
    <>
      {list.map(({ label, value }, i) => (
        <StyledTextField
          value={state[value]}
          key={label}
          disabled={hasStoredBankInfo}
          multiline={value === 'notes'}
          rows={4}
          variant="outlined"
          onChange={e => {
            func({ ...state, [value]: e.target.value });
          }}
          id="standard-full-width"
          label={label}
          placeholder={label}
          fullWidth
          margin="normal"
          autoFocus={autoFocus && i === 0}
        />
      ))}
    </>
  );

  const { notes, ...restFinancial } = financial;
  const presentFieldsValidationError = Object.values(restFinancial).some(v => !v);
  const emailValidationError = generalRules.required(financial.bankerEmail) || generalRules.email(financial.bankerEmail);
  const phoneValidationError = generalRules.phoneNumber(financial.bankerPhoneNumber);
  const bidAllowanceValidationError = validateBidAllowance(financial.requestedBidAllowance);
  const areFinancialFieldsValid = !presentFieldsValidationError
    && !emailValidationError
    && !phoneValidationError
    && !bidAllowanceValidationError;

  const handleClick = async () => {
    if (!hasStoredBankInfo) {
      if (!hasFinancingDocumentStored && AWSData.length) {
        try {
          await new Promise((resolve, reject) => dispatch(saveFileUpload({
            AWSData,
            resolve,
            reject,
          })));
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          return;
        }
      }
      try {
        await new Promise((resolve, reject) => dispatch(createFinancialInfo({
          financial,
          resolve,
          reject,
        })));
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        return;
      }
    }
    history.push(COMPANY_DASHBOARD);
  };

  return (
    <Onboarding
      title="Financing Information"
      subTitle="Please upload the following documents"
      nextDisabled={
        !areFinancialFieldsValid
        || hasStoredBankInfo
        || (
          !terms
          && !hasStoredBankInfo
        )
        || uploadIsPending
      }
      nextClick={handleClick}
      nextText="Submit"
      backButton
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ marginRight: 'auto', maxWidth: '840px' }}
      >
        {createFields(financialFields, setFinancial, financial, true)}
        <StyledTextField
          type="email"
          value={financial.bankerEmail}
          disabled={hasStoredBankInfo}
          onChange={e => {
            if (!touchedEmail) {
              setTouchedEmail(true);
            }
            setFinancial({ ...financial, bankerEmail: e.target.value });
          }}
          onBlur={() => {
            if (!touchedEmail) {
              setTouchedEmail(true);
            }
          }}
          error={touchedEmail && !!emailValidationError}
          helperText={touchedEmail && emailValidationError}
          label="Email of Banker or Private Equity Contact"
          placeholder="Email of Banker or Private Equity Contact"
          variant="outlined"
          margin="normal"
          fullWidth
        />
        <PhoneInput
          classes={{ maskStyles: classes.phoneInput }}
          meta={{
            touched: touchedPhone,
            error: generalRules.phoneNumber(financial.bankerPhoneNumber),
          }}
          placeholder="Telephone Number for Banker or Private Equity Contact"
          disabled={hasStoredBankInfo}
          input={{
            value: financial.bankerPhoneNumber,
            name: 'bankerPhoneNumber',
            onChange: e => {
              if (e.type === 'focus') {
                return;
              }
              if (!touchedPhone) {
                setTouchedPhone(true);
              }
              setFinancial({ ...financial, bankerPhoneNumber: e.target.value });
            },
            onBlur: () => {
              if (!touchedPhone) {
                setTouchedPhone(true);
              }
            },
          }}
        />
        <AmountInput
          classes={{ fieldStyles: classes.amountInput }}
          placeholder="Requested Bid Allowance"
          disabled={hasStoredBankInfo}
          meta={{
            touched: touchedAllowance,
            error: bidAllowanceValidationError,
          }}
          input={{
            value: financial.requestedBidAllowance,
            onChange: e => {
              if (!touchedAllowance) {
                setTouchedAllowance(true);
              }
              setFinancial({
                ...financial,
                requestedBidAllowance: e.target.value,
              });
            },
            onBlur: () => {
              if (!touchedAllowance) {
                setTouchedAllowance(true);
              }
            },
            name: 'requestedBidAllowance',
          }}
        />
        {createFields(financialFields2, setFinancial, financial, false)}
        <FormControlLabel
          className={classes.label}
          control={(
            <Checkbox
              color="default"
              checked={terms || hasStoredBankInfo}
              disabled={hasStoredBankInfo}
              onChange={() => setTerms(!terms)}
            />
          )}
          label="By checking this box, I agree to contact my entity's financial provider listed above, in order to allow representatives from NOW to speak to my financial provider to verify my entity's access to funds"
        />
        <div className={classes.dropZoneContainer}>
          <DropZone
            AWSData={AWSData}
            savedDocuments={financingDocumentsStored}
            disabled={hasFinancingDocumentStored || hasStoredBankInfo}
            isSimpleView
            canRemoveSavedFiles={false}
            placeholderPrompt="Optional upload:"
            placeholder={(
              <span>
                Approval as a buyer can be expedited if you have a current, signed document on
                {' '}
                financing provider&apos;s letterhead, confirming available liquidity.
                {' '}
                Examples
                {' '}
                <span style={{ textDecoration: 'underline' }}>include</span>
                :
                {' '}
                reference letter from bank,
                {' '}
                line of credit or revolver with remaining availability,
                {' '}
                or firm commitment letter from sponsor.
              </span>
            )}
            preSignedUrls={preSignedUrls}
            setAWSData={setAWSData}
            getPreSignedUrls={getPreSignedUrls}
            removeAWSDataFile={removeAWSDataFile}
            documentType={documentTypes.AVAILABLE_LIQUIDITY}
            onSetIsProcessingFiles={setDropZoneIsProcessingFile}
            onSetIsSomeFileNotUploaded={setDropZoneHasFileNotUploaded}
            accept={acceptFileFormats}
          />
        </div>
      </Grid>
    </Onboarding>
  );
}

FinancingInformationView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  authUser: PropTypes.shape({
    // TODO: define props
  }).isRequired,
  AWSData: dropZoneTypes.AWSData.isRequired,
  preSignedUrls: dropZoneTypes.preSignedUrls,
};

FinancingInformationView.defaultProps = {
  preSignedUrls: undefined,
};

export default compose(
  connect(({ auth, financingFiles }) => ({
    authUser: auth.user,
    AWSData: financingFiles.AWSData,
    AWSDataIsSet: financingFiles.AWSDataIsSet,
    preSignedUrls: financingFiles.preSignedUrls,
  })),
  withStyles(styles),
)(FinancingInformationView);
