export default {
  wrapper: {
    display: 'flex',
    flex: '1',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '34px',
    letterSpacing: '0.25px',
    color: '#171721',
  },
  subTitle: {
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: '#4B6377',
  },
  sidebar: {
    background: 'rgba(224, 229, 234, 0.2)',
    width: '25%',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    padding: '25px',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '62px',
  },
  main: {
    width: '75%',
    padding: '90px 70px 50px 70px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  headerBackButton: {
    fontWeight: 500,
    fontSize: '15px',
    letterSpacing: '0.46px',
    textTransform: 'uppercase',
    color: '#7890A6',
    alignSelf: 'flex-start',
  },
  backButton: {
    color: '#7890A6',
    padding: '15px 42px',
    border: '1px solid #7890A6',
  },
  nextButton: {
    background: '#7890A6',
    color: '#ffffff',
    padding: '16px 44px',
    transition: '0.3s',
    '&:hover': {
      background: '#7890A6',
      opacity: 0.8,
      transition: '0.3s',
    },
    '&:disabled': {
      background: '#e0e0e0',
    },
  },
  backArrow: {
    marginRight: '6px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '45px',
  },
  dot: {
    width: '18px',
    height: '18px',
    background: '#fff',
    borderRadius: '50%',
    border: 'solid 1px #4B6377',
  },
  dotNavigation: {
    display: 'flex',
    gap: '20px',
  },
};
