import React, { memo, forwardRef } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';

// components
import CloseModalButton from 'now-frontend-shared/components/CloseModalButton';
import PasswordInput from 'now-frontend-shared/components/inputs/PasswordInput';
import Button from 'now-frontend-shared/components/Button';

// custom hooks
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';

// validate
import { changePasswordValidate } from 'utils/validation/validations';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

// store
import { changePassword } from 'store/actions/authActions';

const ChangePasswordModal = ({ classes, handleSubmit, resetPasswordToken }, ref) => {
  const { setModal } = useModalSetter();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setModal(null);
  };

  const onSubmit = async data => {
    const formData = { ...data, resetPasswordToken };
    return new Promise((resolve, reject) => dispatch(changePassword({ ...formData, resolve, reject })));
  };

  return (
    <Grid container direction="column" tabIndex={-1} className={classes.wrapper} ref={ref}>
      <Grid container direction="column" alignItems="center" className={classes.container}>
        <Grid container justify="center" className={classes.heading}>
          <span>Change Password</span>
        </Grid>

        <Grid component="form" container direction="column" alignItems="center" onSubmit={handleSubmit(onSubmit)}>
          <Grid container className={classes.inputContainer}>
            <Field name="password" component={PasswordInput} props={{ placeholder: 'Enter New Password' }} />
          </Grid>

          <Grid container className={classes.inputContainer}>
            <Field name="confirmPassword" component={PasswordInput} props={{ placeholder: 'Confirm Password' }} />
          </Grid>

          <Grid container justify="center" className={classes.buttonContainer}>
            <Button type="submit" label="Save New Password" buttonColor="green" fullWidth />
          </Grid>
        </Grid>

        <CloseModalButton handleCloseModal={handleCloseModal} />
      </Grid>
    </Grid>
  );
};

export default compose(
  reduxForm({
    form: 'changePassword',
    validate: changePasswordValidate,
  }),
  // don't want/need warnAboutUnsavedForm for this form
  withStyles(styles),
  memo,
  forwardRef,
)(ChangePasswordModal);
