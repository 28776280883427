import React from 'react';
import ReactDOM from 'react-dom';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import closeIcon from './close.svg';
import handShake from './handShake.svg';
import { Button } from '@material-ui/core';
import { mainContentDiv } from '../../../../../../constants';

function WelcomeModal({ classes, open, setOpen }) {
  const targetElement = document.getElementById(mainContentDiv);
  return open && targetElement
    ? ReactDOM.createPortal(
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <img
            src={closeIcon}
            className={classes.icon}
            onClick={() => setOpen(false)}
          />
          <img src={handShake} className={classes.handShake} />
          <Typography variant="body1" className={classes.title}>
            Welcome to your Non-Op Wells company dashboard!
          </Typography>
          <Typography variant="body1" className={classes.description}>
            This dashboard provides up-to-date information on your company's
            account. View all users as well as their respective level of
            authorization. Here you will need to complete the final required
            agreements prior to using the platform.
          </Typography>
          <Button className={classes.button} onClick={() => setOpen(false)}>
            GO TO DASHBOARD
          </Button>
        </div>
      </div>,
      targetElement,
    )
    : null;
}

WelcomeModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default compose(withStyles(styles))(WelcomeModal);
