import { signatureStyle as signature } from 'now-frontend-shared/styles/signature-styles';
import styles from '../../../../now-frontend-shared/styles/document-view-styles';

export default {
  buttonContainer: styles.buttonContainer,
  button: styles.button,
  buttonContainerWithBack: styles.buttonContainerWithBack,
  backButton: styles.backButton,
  signature,
  downloadContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  download: styles.download,
};
