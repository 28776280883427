import { primaryMain, secondaryMain } from '../../../themes/colors';

export default {
  signInWrapper: {
    position: 'relative',
    maxWidth: '400px',

    '@media screen and (max-width: 600px)': {
      alignItems: 'center',
      width: '90%',
    },
  },
  form: {
    width: '100%',
    padding: '0 45px',

    '@media screen and (max-width: 600px)': {
      padding: '0 15px',
    },
  },
  container: {
    padding: '15px',
    background: '#fff',
    borderRadius: '4px',
  },
  registerContainer: {
    marginTop: '10px',
    padding: '20px',
    background: '#fff',
    borderRadius: '4px',
    '@media screen and (max-width: 600px)': {
      padding: '10px',
    },
  },
  heading: {
    padding: '25px',
    fontSize: '32px',
    lineHeight: '37px',
    fontWeight: 'bold',

    '@media screen and (max-width: 600px)': {
      padding: '10px',
      fontSize: '24px',
      lineHeight: '28px',
    },
  },
  loginButton: {
    height: '50px',
    width: '150px',
    margin: '30px',
    borderRadius: '8px',
    background: primaryMain,
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'bold',
    textTransform: 'none',

    '@media screen and (max-width: 600px)': {
      margin: '10px',
    },
    '&:hover': {
      background: secondaryMain,
    },
  },
  forgotButton: {
    margin: '10px',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'normal',
    color: '#4F4F4F',
    borderBottom: '1px solid #4F4F4F',
    cursor: 'pointer',

    '&:visited': {
      color: '#4F4F4F',
    },
  },
  registerLink: {
    marginTop: '10px',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'bold',
    color: '#4F4F4F',
    borderBottom: '1px solid #4F4F4F',
    cursor: 'pointer',
    textDecoration: 'none',
    textTransform: 'none',
  },
};
