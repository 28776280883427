import newTheme from 'themes/theme';

export default {
  footer: {
    background: '#F8F8F8',
  },
  // TODO: [FEATURE][CLEANUP] remove this when the old design is no longer in use
  wrapper: {
    height: '70px',
    margin: '0 auto',
  },
  newWrapper: {
    margin: '0 auto',
    '@media (max-width: 600px)': {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      padding: '10px',
    },
  },
  // TODO: [FEATURE][CLEANUP] remove this when the old design is no longer in use
  link: {
    padding: '0 28px',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 'normal',
    cursor: 'pointer',
    color: '#333333',
    textDecoration: 'none',
    textTransform: 'none',

    '@media screen and (max-width: 600px)': {
      padding: '0 8px',
      fontSize: '14px',
      lineHeight: '16px',
    },
  },
  newLink: {
    /* font styles from Typography/Body2 */
    padding: '0 25px',
    fontSize: '14px',
    lineHeight: '20.02px',
    letterSpacing: '0.15px',
    fontWeight: '400',
    cursor: 'pointer',
    color: newTheme.palette.text.primary,
    textDecoration: 'none',
    textTransform: 'none',

    '@media screen and (max-width: 1200px)': {
      padding: '0 14px',
      lineHeight: '16px',
    },

    '@media screen and (max-width: 950px)': {
      padding: '0 8px',
    },
  },
  // TODO: [FEATURE][CLEANUP] remove this when the old design is no longer in use
  activeLink: {
    color: '#100081',
    fontWeight: 'bold',
  },
  newActiveLink: {
    fontWeight: '700',
  },
};
