import { makeAxiosRequest } from 'utils/apiMethods';

export default {
  cancelBidAllowanceRequest: makeAxiosRequest(
    '/my-bid-allowance-request/cancel',
    { method: 'put' },
  ),
  getBidAllowanceDetails: makeAxiosRequest('/my-bid-allowance-details', {
    method: 'get',
  }),
  getBidAllowanceRequest: makeAxiosRequest('/my-bid-allowance-request', {
    method: 'get',
  }),
  sendSellerAgreement: companyId => makeAxiosRequest(
    `/companies/${companyId}/seller-agreement`,
    {
      method: 'post',
    },
    true,
  ),
  sendBuyerAgreement: companyId => makeAxiosRequest(
    `/companies/${companyId}/buyer-agreement`,
    {
      method: 'post',
    },
    true,
  ),
  setBidAllowanceRequest: makeAxiosRequest('/my-bid-allowance-request', {
    method: 'put',
  }),
  getSellerAgreementTemplate: makeAxiosRequest(
    '/seller-agreement-template',
    {
      method: 'get',
    },
    true,
  ),
  updateSellerAgreement: makeAxiosRequest('/profile/seller-agreement', {
    method: 'put',
  }),
  getBuyerAgreementTemplate: makeAxiosRequest(
    '/buyer-agreement-template',
    {
      method: 'get',
    },
    true,
  ),
  updateBuyerAgreement: makeAxiosRequest('/profile/buyer-agreement', {
    method: 'put',
  }),
  updateUserInformation: makeAxiosRequest('/users', { method: 'put' }, true),
};
