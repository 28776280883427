import React, { memo } from 'react';
import { connect, useDispatch } from 'react-redux';

import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// icons
import arrowIcon from 'now-frontend-shared/assets/icons/menu_arrow.svg';

// styles and components from material-ui
import { withStyles, styled } from '@material-ui/core/styles';
import {
  Grid, Menu, MenuItem, Button,
} from '@material-ui/core';

// styles
import styles from './styles';

// store
import { removeUserData } from 'store/actions/authActions';

import { getUserDisplayName } from 'utils/user-helpers';
import { isAdminOrCompliancePerson } from 'now-shared/validation/admin-upsert-user';
import { getRedirectPathAfterSignIn } from 'store/sagas/mainPageSagas';

const HeaderMenu = ({
  classes,
  user,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const userIsApproved = user.approvalStatus?.title === 'approved';
  const userDisplayName = getUserDisplayName(user);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    history.push('/');
    dispatch(removeUserData());
    setAnchorEl(null);
  };

  return (
    <Grid item className={classes.menuContainer}>
      <StyledMenuButton
        className={classes.headerButton}
        color="inherit"
        aria-controls="menu"
        aria-haspopup="true"
        toggleRotate={Boolean(anchorEl)}
        endIcon={<img alt="arrow-icon" src={arrowIcon} data-cy="arrow-icon" />}
        onClick={handleClick}
        data-cy="profile-button"
      >
        {userDisplayName}
      </StyledMenuButton>

      <StyledMenu id="menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {!userIsApproved && !isAdminOrCompliancePerson(user) && (
          <StyledMenuItem
            component={Link}
            to={getRedirectPathAfterSignIn(user)}
            onClick={handleClose}
          >
            Registration
          </StyledMenuItem>
        )}

        <StyledMenuItem data-cy="logout-button" onClick={handleLogOut}>Log Out</StyledMenuItem>
      </StyledMenu>
    </Grid>
  );
};

const StyledMenu = withStyles({
  list: {
    padding: 0,
  },

  paper: {
    border: 0,
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles({
  root: {
    width: '150px',
    justifyContent: 'center',
    marginBottom: '2px',
    background: '#F2F2F2',
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 'normal',
    color: '#282424',
  },
})(MenuItem);

const StyledMenuButton = styled(({ toggleRotate, children, ...other }) => <Button {...other}>{children}</Button>)({
  '& img': {
    transform: props => props.toggleRotate && 'rotateZ(-180deg)',
    transition: 'transform 0.25s ease-in-out',
  },
});

HeaderMenu.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  user: PropTypes.shape({
    // TODO: [TYPE] specify props
  }),
};

HeaderMenu.defaultProps = {
  user: undefined,
};

export default compose(
  connect(({ auth }) => ({
    user: auth.user,
  })),
  withStyles(styles),
  memo,
)(HeaderMenu);
