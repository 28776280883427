import {
  all, fork, take, takeEvery, takeLatest, call, put, select,
} from 'redux-saga/effects';

// actions
import {
  getAllBasins,
  changeUserBasins,
  setDefaultBasins,
  setNotificationValue,
  addUserBasin,
  removeUserBasin,
  setBasinPendingValue,
  updatePassword,
  setPasswordIsUpdated,
} from 'store/actions/settingsActions';

// api methods
import Api from 'api/profile';

// storage helpers functions
import * as storage from 'now-frontend-shared/utils/storage';

function* ensureGettingAllBasins() {
  const { data } = yield call(Api.getAllBasins);
  yield put(setDefaultBasins(data));
}

function* ensureChangeSettingsNotificationValue({ payload }) {
  const currentNotificationName = payload.emailType;
  const { value } = payload;
  yield put({ type: setNotificationValue.request, payload: { [currentNotificationName]: true } });
  try {
    yield call(Api.setEmailNotification, {
      data: JSON.stringify(payload),
    });
    yield put({
      type: setNotificationValue.success,
      payload: {
        notificationsSwitches: { [currentNotificationName]: value },
        notificationsPending: { [currentNotificationName]: false },
      },
    });
  } catch (err) {
    yield put({
      type: setNotificationValue.failure,
      err,
      payload: {
        notificationsSwitches: { [currentNotificationName]: !value },
        notificationsPending: { [currentNotificationName]: false },
      },
    });
  }
}

function* watchChangeSettingsNotificationValue() {
  yield takeEvery(setNotificationValue.type, ensureChangeSettingsNotificationValue);
  yield take(setNotificationValue.success);
}

function* sendUserBasin({ payload }) {
  yield call(Api.sendBasinForEmailNotification, {
    data: { basinId: payload.id },
  });
  yield put({ type: addUserBasin.type, payload });
}

function* deleteUserBasin({ payload }) {
  yield call(Api.removeBasinForEmailNotification, {
    data: { basinId: payload.id },
  });
  yield put({ type: removeUserBasin.type, payload });
}

function* ensureChangeUserBasins({ payload }) {
  yield put(setBasinPendingValue({ [payload.title]: true }));
  try {
    const userBasins = yield select(({ settings }) => settings.basins);
    if (userBasins.find(basin => basin.id === payload.id)) {
      yield call(deleteUserBasin, { payload });
    } else {
      yield call(sendUserBasin, { payload });
    }
    yield put({ type: changeUserBasins.success, payload });
  } catch (err) {
    yield put({ type: changeUserBasins.failure, err });
  }
  yield put(setBasinPendingValue({ [payload.title]: false }));
}

function* watchChangeUserBasins() {
  yield takeEvery(changeUserBasins.type, ensureChangeUserBasins);
}

function* ensureUpdatePassword({ payload }) {
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    yield call(Api.updatePassword, {
      data: JSON.stringify(payload),
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    yield put({ type: updatePassword.success });
    yield put(setPasswordIsUpdated(true));
  } catch (err) {
    yield put({ type: updatePassword.failure, err });
  }
}

function* watchUpdatePassword() {
  yield takeLatest(updatePassword.type, ensureUpdatePassword);
  yield take(updatePassword.success);
}

export default function* settingsSagas() {
  yield all([
    takeLatest(getAllBasins.type, ensureGettingAllBasins),
    fork(watchChangeSettingsNotificationValue),
    fork(watchChangeUserBasins),
    fork(watchUpdatePassword),
  ]);
}
