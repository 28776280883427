import React, { useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Button } from '@material-ui/core';
import { mainContentDiv } from '../../../../../../constants';

function OnboardingModal({
  classes, open, setOpen, children, right, y, title, content,
}) {
  const targetElement = document.getElementById(mainContentDiv);
  const infoBoxRef = React.createRef(null);
  const [infoBoxDimensions, setInfoBoxDimensions] = React.useState({ x: 0, y });
  const headerElement = document.getElementById('header')?.getBoundingClientRect()?.height || 0;
  useLayoutEffect(() => {
    if (infoBoxRef.current) {
      setInfoBoxDimensions({ x: right + 50, y: y - (infoBoxRef.current.getBoundingClientRect().height / 2) + headerElement });
    }
  }, [right, y]);
  return Number.isInteger(open) && targetElement
    ? ReactDOM.createPortal(
      <div className={classes.wrapper}>
        {children}
        <div className={classes.container} ref={infoBoxRef} style={{ top: infoBoxDimensions.y, left: infoBoxDimensions.x }}>
          <Typography variant="body1" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {content}
          </Typography>
          <Button className={classes.button} onClick={() => setOpen()}>
            Next
          </Button>
        </div>
      </div>,
      targetElement,
    )
    : null;
}

OnboardingModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.number.isRequired,
  setOpen: PropTypes.func.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default compose(withStyles(styles))(OnboardingModal);
