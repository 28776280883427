import { secondaryMain, primaryMain, white } from 'now-frontend-shared/themes/colors';

export default {
  button: {
    height: '48px',
    width: '283px',
    padding: '13px',
    borderRadius: '4px',
    border: 'unset',
    outline: 'none',
    color: white,
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'none',
    backgroundColor: secondaryMain,
    boxShadow: 'unset',
    letterSpacing: '0.003em',
    zIndex: 2,

    '&:hover': {
      backgroundColor: primaryMain,
      color: '#fff',
    },

    '@media screen and (max-width: 600px)': {
      width: '100%',
      marginTop: '16px',
    },
  },
  disabled: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
    userSelect: 'none',
    opacity: 0.5,
  },
};
