import '@fontsource/archivo-black';
import { landingPageLargeText, white } from 'now-frontend-shared/themes/colors';

export default {
  wrapper: {
    backgroundImage: "url('/images/overview_oil_image.png')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: '38px 34px',
  },
  backgroundContainer: {
  },
  background: {
    minHeight: '100vh',
    minWidth: '100vw',
    zIndex: 0,
    'object-fit': 'cover',

    '@media screen and (max-width: 1100px)': {
      transform: 'translateX(20%)',
      minWidth: 'unset',
    },

    '@media screen and (max-width: 600px)': {
      minHeight: 'unset',
      height: '100vh',
      transform: 'translateX(18%)',
    },

    '@media (aspect-ratio: 256/135)': {
      width: '100vw',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    zIndex: 3,
    left: '170px',
    top: '510px',
    '@media screen and (max-width: 950px)': {
      position: 'initial',
      margin: '35px 0',
    },
  },
  button: {
    width: '236px',
  },
  unionContainer: {
    width: '1031px',
    height: '937px',
    position: 'relative',
    '@media screen and (max-width: 1440px)': {
      transform: 'scale(0.8)',
    },
    '@media screen and (max-width: 1100px)': {
      transform: 'scale(0.7)',
    },
    '@media screen and (max-width: 950px)': {
      transform: 'scale(1)',
      width: '100%',
      height: 'inherit',
    },
  },
  whiteContainer: {
    width: '806px',
    height: '663px',
    position: 'absolute',
    '@media screen and (max-width: 950px)': {
      width: '100%',
      height: 'inherit',
      position: 'relative',
    },
  },
  unionWhite: {
    width: '100%',
    height: 'auto',
    position: 'absolute',
    '@media screen and (max-width: 950px)': {
      display: 'none',
    },
  },
  unionWhiteText: {
    width: '510px',
    height: '392.3px',
    textAlign: 'justify',
    position: 'absolute',
    zIndex: 3,
    left: '170px',
    top: '50px',
    color: landingPageLargeText,
    '@media screen and (max-width: 950px)': {
      position: 'initial',
      width: '100%',
      height: 'inherit',
    },
  },
  unionWhiteHeader: {
    fontFamily: 'Archivo Black',
    fontSize: '60px',
    fontWeight: 'normal',
    marginBottom: '10px',
    '@media screen and (max-width: 950px)': {
      color: '#ffffff',
      fontSize: '38px',
    },
  },
  unionWhiteDescription: {
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: 'normal',
    lineHeight: 1.83,
    '@media screen and (max-width: 950px)': {
      color: '#ffffff',
      fontSize: '20px',
    },
  },
  greyContainer: {
    width: '580px',
    height: '471px',
    position: 'absolute',
    bottom: 0,
    right: 0,
    '@media screen and (max-width: 950px)': {
      position: 'initial',
      width: '100%',
      height: 'inherit',
    },
  },
  unionGrey: {
    width: '100%',
    height: 'auto',
    position: 'absolute',
    '@media screen and (max-width: 950px)': {
      display: 'none',
    },
  },
  unionGreyText: {
    width: '440px',
    height: '367px',
    textAlign: 'justify',
    position: 'absolute',
    zIndex: 3,
    right: '115px',
    bottom: '60px',
    color: white,
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: 'normal',
    lineHeight: 1.83,

    '@media screen and (max-width: 950px)': {
      position: 'initial',
      width: '100%',
      height: 'inherit',
      fontSize: '20px',
    },
  },
};
