import PhoneInputStyles from 'now-frontend-shared/components/inputs/PhoneInput/styles';
import ssnOrTinInputStyles from 'now-frontend-shared/components/inputs/SsnOrTinInput/styles';

export default {
  root: {},
  link: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: 0.15,
    color: 'rgba(0, 0, 0, 0.6)',
  },
  linkContainer: {
    marginTop: '20px',
    textAlign: 'left',
    width: '100%',
  },
  phoneInput: PhoneInputStyles.phoneInput,
  ssnOrTinInput: ssnOrTinInputStyles.input,
  homeButton: {
    border: '1px solid rgba(178, 157, 121, 0.5)',
    color: '#B29D79',
    marginTop: '24px',
  },
  emailButton: {
    background: 'rgba(120, 144, 166, 0.08)',
    border: '1px solid rgba(120, 144, 166, 0.5)',
    boxSizing: 'border-box',
    borderRadius: '4px',
    padding: '8px 22px',
    color: '#7890A6',
    fontWeight: '500',
    fontSize: '15px',
    textTransform: 'uppercase',
    minWidth: '200px',
    height: '56px',
    marginTop: '52px',
  },
  separator: {
    textAlign: 'center',
    width: '100%',
    margin: '5px 0',
  },
  input: {
    width: 'calc(50% - 10px)',
  },
};
