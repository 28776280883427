import '@fontsource/archivo-black';
import { white } from 'now-frontend-shared/themes/colors';

export default {
  wrapper: {
    backgroundImage: "url('/images/rig-night.png')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: '105px 10px',
    textAlign: 'center',
  },
  requestDemoHeader: {
    fontFamily: 'Archivo Black',
    fontColor: white,
    fontSize: '60px',
    color: white,
    '@media (max-width: 900px)': {
      fontSize: '40px',
    },
  },
  buttonContainer: {
    marginTop: '30px',
  },
  button: {
    width: '236px',
  },
};
