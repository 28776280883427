import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// components
import BurgerMenu from 'components/BurgerMenu';
import HeaderMenu from '../HeaderMenu';

// icons
import IntegratedLogo from 'now-frontend-shared/assets/icons/2022-06-01_high_res_logo.png';

// styles and components from material-ui
import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';

// styles
import styles, { newHeaderPaddingLeft, newHeaderPaddingRight } from './styles';
import newTheme from 'themes/theme';
import { desktopContentMinWidthExclusive, desktopContentMinWidthExclusiveInPixels, headerNewHeight } from 'layouts/styles';

import useWindowScrollY from 'now-frontend-shared/hooks/useWindowScrollY';
import useIsWindowGreaterThanWidth from 'now-frontend-shared/hooks/useIsWindowGreaterThanWidth';

import { isAdminOrCompliancePerson } from 'now-shared/validation/admin-upsert-user';
import {
  BUYER_DASHBOARD, COMPANY_DASHBOARD, DASHBOARD, SELLER_DASHBOARD,
} from 'constants/registrationFlow';

const NewHeaderMenu = withStyles({
  menuContainer: {
    padding: '0 0px',

    [`@media screen and (max-width: ${desktopContentMinWidthExclusive})`]: {
      display: 'none',
    },
  },
  headerButton: {
    textDecoration: 'none',
    textTransform: 'none',
    margin: '0 0px',
    textAlign: 'center',

    '& span': {
      fontSize: '15px',
      lineHeight: '22px',
      fontWeight: 'normal',
      color: newTheme.palette.text.primary,
    },

    [`@media screen and (max-width: ${desktopContentMinWidthExclusive})`]: {
      margin: '0 10px',
    },
  },
})(HeaderMenu);

export function isDashboardLocation(location) {
  return [
    DASHBOARD,
    BUYER_DASHBOARD,
    SELLER_DASHBOARD,
    COMPANY_DASHBOARD,
  ].includes(location.pathname);
}

export function isDashboardLinkActive(_match, location) {
  return isDashboardLocation(location);
}

const Header = ({
  classes,
  hasBuyerAgreement,
  hasSellerAgreement,
  isAdminOrComplianceUser,
  isAuthorized,
  user,
}) => {
  const userIsApproved = user.approvalStatus?.title === 'approved';
  const companyIsApprovedAndActive = !!user.company?.approved && !!user.company?.active;

  const windowScrollY = useWindowScrollY();
  const isLessThanDesktopWidth = !useIsWindowGreaterThanWidth(desktopContentMinWidthExclusiveInPixels);

  return (
    <ThemeProvider theme={newTheme}>
      <CssBaseline />
      <Grid
        id="header"
        component="header"
        container
        style={{
          backgroundColor: newTheme.palette.primary.light,
          height: headerNewHeight,
          overflow: 'hidden',
          paddingLeft: newHeaderPaddingLeft,
          paddingRight: newHeaderPaddingRight,
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className={classes.newWrapper}
        >
          <Grid
            item
            className={classes.newBurgerMenu}
            style={{
              ...isLessThanDesktopWidth && {
                transform: `translateY(-${windowScrollY}px)`,
              },
            }}
          >
            <BurgerMenu />
          </Grid>

          <Grid
            item
            className={classes.newLogoContainer}
            style={{
              ...isLessThanDesktopWidth && {
                transform: `translateY(-${windowScrollY}px)`,
              },
            }}
          >
            <NavLink to="/">
              <img src={IntegratedLogo} alt="NOW-PEP Logo" className={classes.imgLogo} />
            </NavLink>
          </Grid>

          {isAuthorized && (
          <>
            {(userIsApproved || isAdminOrComplianceUser) && (
            <>
              <Grid item className={classes.newLinkContainer} justifyContent="flex-end">
                <NavLink
                  to={DASHBOARD}
                  className={classes.newLink}
                  data-cy="dashboardNav"
                  activeClassName={classes.newActiveLink}
                  exact
                  isActive={isDashboardLinkActive}
                >
                  Dashboard
                </NavLink>
                {(
                  isAdminOrComplianceUser
                  || (
                    companyIsApprovedAndActive
                    && (
                      hasBuyerAgreement
                      || hasSellerAgreement
                    )
                  )
                ) && (
                  <NavLink
                    to="/listings"
                    className={classes.newLink}
                    data-cy="listingsNav"
                    activeClassName={classes.newActiveLink}
                    exact
                  >
                    Listings
                  </NavLink>
                )}

                {hasSellerAgreement && companyIsApprovedAndActive && (
                  <NavLink
                    to="/my-listings"
                    className={classes.newLink}
                    data-cy="myListingsNav"
                    activeClassName={classes.newActiveLink}
                    exact
                  >
                    My Listings
                  </NavLink>
                )}

                {hasBuyerAgreement && companyIsApprovedAndActive && (
                  <NavLink
                    to="/my-bids"
                    className={classes.newLink}
                    data-cy="myBidsNav"
                    activeClassName={classes.newActiveLink}
                    exact
                  >
                    My Bids
                  </NavLink>
                )}

                {(
                  isAdminOrComplianceUser
                  || (
                    companyIsApprovedAndActive
                    && (
                      hasBuyerAgreement
                      || hasSellerAgreement
                    )
                  )
                ) && (
                  <NavLink
                    to="/map"
                    className={classes.newLink}
                    data-cy="mapNav"
                    activeClassName={classes.newActiveLink}
                    exact
                  >
                    Map
                  </NavLink>
                )}
              </Grid>
            </>
            )}

            <NewHeaderMenu />
          </>
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

Header.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  hasBuyerAgreement: PropTypes.bool,
  hasSellerAgreement: PropTypes.bool,
  isAdminOrComplianceUser: PropTypes.bool,
  isAuthorized: PropTypes.bool,
  user: PropTypes.shape({
    // TODO: [TYPE] specify props
  }),
};

Header.defaultProps = {
  user: undefined,
};

export default compose(
  connect(({ auth }) => ({
    hasBuyerAgreement: auth.user.company?.hasBuyerAgreement,
    hasSellerAgreement: auth.user.company?.hasSellerAgreement,
    isAdminOrComplianceUser: isAdminOrCompliancePerson(auth.user),
    isAuthorized: auth.authInfo.isAuthorized,
    user: auth.user,
  })),
  withStyles(styles),
  memo,
)(Header);
