export default {
  root: {
    padding: '86px 0',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '34px',
    textAlign: 'center',
    letterSpacing: '0.25px',
    color: '#171721',
  },
  questionContainer: {
    display: 'flex',
    gap: '20px',
    width: '100%',
    flexDirection: 'column',
    marginTop: '50px',
  },
  optionContainer: {
    marginTop: '20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  backButton: {
    color: '#7890A6',
  },
  textTitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '0.15px',
    color: '#000000',
    textTransform: 'uppercase',
  },
  button: {
    backgroundColor: '#7890A6',
    color: '#ffffff',
  },
  radio: {
    boxShadow: '0px 0px 0px 1px #E0E0E0',
    borderRadius: '8px',
    margin: '8px 0',
    padding: '5px 20px 5px 0',
  },
};
