import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Button from 'now-frontend-shared/components/Button';

// styles
import styles from './styles';

const BottomSection = ({ classes }) => {
  const history = useHistory();

  const handleOpenContactUsForm = () => {
    history.push({ pathname: '/contact-us' });
  };

  return (
    <Grid container direction="column" justify="center" alignItems="center" className={classes.wrapper}>
      <Grid container item justifyContent="center" className={classes.requestDemoContainer}>
        <div className={classes.requestDemoContent}>
          <span className={classes.requestDemoHeader}>Ready to see a demo?</span>
          <Grid container item className={classes.buttonContainer} justifyContent="center">
            <Grid item className={classes.button}>
              <Button
                label="REQUEST A DEMO"
                buttonColor="white"
                fullWidth
                onClick={handleOpenContactUsForm}
                className={classes.button}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

BottomSection.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), memo)(BottomSection);
