import { primaryMain, secondaryMain } from '../../../themes/colors';

export default {
  signUpWrapper: {
    position: 'relative',
    maxWidth: '400px',

    '@media screen and (max-width: 600px)': {
      alignItems: 'center',
      width: '90%',
      overflowY: 'scroll',
      height: '90vh',
    },
  },
  form: {
    width: '100%',
    padding: '0 45px',

    '@media screen and (max-width: 600px)': {
      padding: '0 15px',
    },
  },
  container: {
    padding: '15px',
    background: '#fff',
    borderRadius: '4px',
  },
  registerContainer: {
    marginTop: '10px',
    padding: '20px',
    background: '#fff',
    borderRadius: '4px',

    '@media screen and (max-width: 600px)': {
      padding: '10px',
    },
  },
  heading: {
    // padding: '25px',
    fontSize: '32px',
    lineHeight: '37px',
    fontWeight: 'bold',

    '@media screen and (max-width: 600px)': {
      padding: '10px',
      fontSize: '24px',
      lineHeight: '28px',
    },
  },
  registerButton: {
    height: '50px',
    width: '150px',
    margin: '30px',
    borderRadius: '8px',
    background: primaryMain,
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'bold',
    textTransform: 'none',

    '@media screen and (max-width: 600px)': {
      margin: '10px',
    },
    '&:hover': {
      background: secondaryMain,
    },
  },

  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  signupHeader: {
    margin: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitAndBackButtonWrapper: {
    marginTop: 20,
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textField: {
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.6)',
    order: 0,
    marginBottom: '25px',
  },
  password: {
    '& input': {
      boxSizing: 'content-box',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '0px',
    },
    '& svg': {
      marginRight: '8px',
    },
    '& .input-password__marker': {
      marginLeft: '5px',
      marginTop: '5px',
    },
    width: '100%',
    boxSizing: 'content-box',
    marginBottom: '12px',
  },
  checkboxText: {
    fontWeight: 'normal',
    fontSize: 16,
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.6)',
    marginBottom: '10px',
    justifyContent: 'center',
  },
  submit: {
    background: '#7890A6',
    color: '#ffffff',
    margin: 0,
    borderRadius: '4px',
    fontWeight: 500,
    fontSize: 15,
    letterSpacing: '0.46px',
    textTransform: 'uppercase',
  },
};
