export default {
  wrapper: {
    borderRadius: '10px',
    width: '100%',
    height: '100%',
    background: 'hsla(0, 0%, 0%, 0.5)',
    position: 'absolute',
  },
  container: {
    width: '366px',
    height: '444px',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    gap: '20px',
    border: '1px solid rgba(33, 150, 243, 0.5)',
    borderRadius: '4px',
    position: 'absolute',
  },
  title: {
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '0.25px',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '24px',
  },
  description: {
    fontSize: '20px',
    letterSpacing: '0.15px',
    color: '#000000',
  },
  button: {
    background: '#2196F3',
    boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    color: '#ffffff',
    padding: '8px 26px',
    width: 'fit-content',
    alignSelf: 'flex-end',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#2196F3',
      opacity: '0.8',
      transition: '0.3s',
    },
  },
};
