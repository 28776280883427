import styles from '../../../../now-frontend-shared/styles/document-view-styles';

export default {
  text: {
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: '#4B6377',
  },
  complete: {
    color: '#008000',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '40px 0',
  },
  download: styles.download,
  downloadContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  buttonUpload: {
    background: '#FFFFFF',
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    fontSize: '16px',
    letterSpacing: '0.15px',
    textTransform: 'none',
    fontWeight: 'normal',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    padding: '4px',
  },
  label: {
    width: '100%',
    marginTop: '10px',
  },
  subText: {
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '0.15px',
    color: '#000000',
    width: '100%',
    margin: '31px 0 0px 0',
  },
  liText: {
    fontWeight: 'normal',
    fontSize: '16px',
    letterSpacing: '0.15px',
    color: '#000000',
    width: '100%',
    margin: '31px 0 0px 0',
  },
  uploadContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  subTitle: {
    fontWeight: 'normal',
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: '#4B6377',
  },
  edit: {
    fontWeight: 'normal',
    fontSize: '16px',
    letterSpacing: '0.15px',
    color: '#2196F3',
  },
  itemContainer: {
    width: '100%',
  },
  itemContainer_li: {
    listStyle: 'auto',
    fontSize: '24px',
  },
  dropZoneContainer: {
    marginTop: '35px',
    width: '100%',
  },
};
