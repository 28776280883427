import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styles and components from material-ui
import {
  withStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';

import { isAdminOrCompliancePerson } from 'now-shared/validation/admin-upsert-user';

// styles
import styles from './styles';
import newTheme from 'themes/theme';
import { footerNewHeight } from 'layouts/styles';
import CookieBanner from 'components/CookieBanner';

const Footer = ({
  classes, isAdminOrComplianceUser, user, isAuthorized,
}) => {
  const userIsApproved = user.approvalStatus?.title === 'approved';
  return (
    <ThemeProvider theme={newTheme}>
      <CssBaseline />
      <CookieBanner />
      <Grid
        component="footer"
        container
        style={{
          backgroundColor: newTheme.palette.primary.light,
          minHeight: footerNewHeight,
        }}
      >
        <Grid container direction="row" alignItems="center" justifyContent="center" className={classes.newWrapper}>
          {isAuthorized && (isAdminOrComplianceUser || userIsApproved) && (
            <>
              <NavLink
                to="/about-us"
                className={classes.newLink}
                activeClassName={classes.newActiveLink}
                data-cy="aboutUs"
              >
                About Us
              </NavLink>
            </>
          )}

          <NavLink to="/contact-us" className={classes.newLink} activeClassName={classes.newActiveLink} data-cy="contactUs">
            Contact Us
          </NavLink>

          <NavLink
            to="/terms-and-conditions"
            className={classes.newLink}
            activeClassName={classes.newActiveLink}
            data-cy="termsAndConditions"
          >
            Terms & Conditions
          </NavLink>

          <NavLink to="/faq" className={classes.newLink} activeClassName={classes.newActiveLink} data-cy="faq">
            FAQ
          </NavLink>

          <NavLink
            to="/privacy-policy"
            className={classes.newLink}
            activeClassName={classes.newActiveLink}
            data-cy="privacyPolicy"
          >
            Privacy Policy
          </NavLink>

          <NavLink to="/business-continuity-disclosure" className={classes.newLink} data-cy="businessContinuityDisclosure">
            Business Continuity Disclosure
          </NavLink>

          <a href="https://finra.org" target="_blank" rel="noopener noreferrer" className={classes.newLink} data-cy="finra">
            FINRA
          </a>

          <a href="https://sipc.org" target="_blank" rel="noopener noreferrer" className={classes.newLink} data-cy="sipc">
            SIPC
          </a>

          <a href="https://brokercheck.finra.org/" target="_blank" rel="noopener noreferrer" className={classes.newLink} data-cy="brokerCheck">
            BrokerCheck
          </a>

        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

Footer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isAdminOrComplianceUser: PropTypes.bool,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  isAuthorized: PropTypes.bool,
};

Footer.defaultProps = {
  isAuthorized: undefined,
  isAdminOrComplianceUser: undefined,
};

export default compose(
  connect(({ auth }) => ({
    isAdminOrComplianceUser: isAdminOrCompliancePerson(auth.user),
    isAuthorized: auth.authInfo.isAuthorized,
    user: auth.user,
  })),
  withStyles(styles),
  memo,
)(Footer);
