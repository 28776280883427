import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as SuccessIcon } from 'now-frontend-shared/assets/icons/success-registration.svg';
import { Grid, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    padding: '86px 0',
  },
  title: {
    marginTop: '24px',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '36px',
    textAlign: 'center',
    color: '#171721',
  },
  text: {
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: '#1E1E1E',
    marginTop: '8px',
    maxWidth: '371px',
  },
});

export default function Success({ title, message, children = null }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid
        style={{ padding: '100px 0' }}
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <SuccessIcon />
        <Typography variant="h2" className={classes.title}>
          {title || 'Thank you!'}
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {message}
        </Typography>
        {children}
      </Grid>
    </div>
  );
}

Success.defaultProps = {
  message: '',
  title: '',
  children: null,
};

Success.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
};
