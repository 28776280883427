import PhoneInputStyles from 'now-frontend-shared/components/inputs/PhoneInput/styles';

export default {
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '34px',
    textAlign: 'center',
    letterSpacing: '0.25px',
    color: '#171721',
    marginBottom: '30px',
  },
  text: {
    margin: '10px 0',
  },
  phoneInput: PhoneInputStyles.phoneInput,
  amountInput: {
    height: '56px',
    fontSize: '16px',
    fontWeight: 'normal',
    marginTop: '6px',
  },
  label: {
    fontWeight: 'normal',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: '20px',
  },
  dropZoneContainer: {
    marginTop: '35px',
    width: '100%',
  },
  complete: {
    color: '#008000',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '40px 0',
  },
};
