import { compose } from 'redux';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';

// components
import AmountInput from 'now-frontend-shared/components/inputs/AmountInput';

// styles
import styles from './styles';

export default compose(withStyles(styles))(AmountInput);
