import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from '@material-ui/icons/SaveAlt';

import { nonOpWellsDateTime } from 'now-shared/helpers/time-helpers';

import styles from './styles';
import {
  withStyles,
} from '@material-ui/core/styles';

import { downloadListingDocuments } from 'now-frontend-shared/components/ListingForm/components/GeneralInformation';

const DocumentsTable = ({
  classes,
  documents,
  onDownload,
  listingId,
}) => {
  const [filesPreparing, setFilesPreparing] = useState([]);

  const downloadFile = async ({
    id: documentId,
    downloadUrl,
    filename,
    key,
    downloadAll,
  }) => downloadListingDocuments({
    documentId,
    documents,
    downloadUrl,
    filename,
    key,
    downloadAll,
    listingId,
    filesPreparing,
    setFilesPreparing,
    onDownload,
  });

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableCell}>
            Document Name
          </TableCell>
          <TableCell className={classes.tableCell}>
            Uploaded At
          </TableCell>
          <TableCell className={classes.tableCell}>
            Download
          </TableCell>
          <TableCell className={classes.tableCell}>
            Seller Comments
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {documents.map(document => (
          <TableRow key={document.id}>
            <TableCell className={classes.tableCell}>
              {document.filename}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {nonOpWellsDateTime(new Date(document.createdAt)).toFormat(
                'MM / dd / yyyy / t ZZZZ',
              )}
            </TableCell>
            <TableCell className={classes.tableCell}>
              <IconButton
                className={classes.button}
                onClick={() => downloadFile(document)}
              >
                <DownloadIcon />
              </IconButton>
            </TableCell>
            <TableCell className={classes.tableCell}>
              {document.comment}
            </TableCell>
          </TableRow>
        ))}
        {documents.length === 0 && (
          <TableRow>
            <TableCell colSpan={4}>No documents</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

DocumentsTable.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      filename: PropTypes.string.isRequired,
      downloadUrl: PropTypes.string.isRequired,
      comment: PropTypes.string,
    }).isRequired,
  ).isRequired,
  onDownload: PropTypes.func.isRequired,
  listingId: PropTypes.func.isRequired,
};

export default withStyles(styles)(DocumentsTable);
