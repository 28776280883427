import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';

import styles from './styles';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import BackArrow from './components/backArrow.svg';
import { navigateToNextPage, navigateToPreviousPage } from 'constants/registrationFlow';

const OnboardingLayout = ({
  children,
  classes,
  title,
  backButton,
  subTitle,
  nextDisabled,
  nextClick,
  dotNavigationLegend,
  backClick,
  nextText,
}) => {
  const renderDotNavigation = () => (
    <div className={classes.dotNavigation}>
      {Array.from({ length: 7 }, (_, i) => <div key={i} className={classes.dot} style={(i + 1) <= dotNavigationLegend ? { background: '#4B6377' } : {}} />)}
    </div>
  );
  const history = useHistory();
  return (
    <section className={classes.wrapper}>
      <aside className={classes.sidebar} style={backButton ? { justifyContent: 'flex-start' } : { paddingTop: '88px' }}>
        {backButton && (
        <Button className={classes.headerBackButton} onClick={() => history.goBack()}>
          <img src={BackArrow} alt="Left arrow icon" className={classes.backArrow} />
          BACK
        </Button>
        )}
        <div className={classes.contentContainer}>
          <Typography variant="h1" component="div" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body1" component="div" className={classes.text}>
            {subTitle}
          </Typography>
        </div>
        {dotNavigationLegend !== -1 && <div className={classes.dotNavigation}>{renderDotNavigation()}</div>}
      </aside>
      <div className={classes.main}>
        {children}
        <div className={classes.footer} style={backButton ? { justifyContent: 'flex-end' } : {}}>
          {!backButton && (
          <Button
            className={classes.backButton}
            onClick={() => {
              if (typeof backClick === 'function') {
                backClick();
              } else {
                history.push(navigateToPreviousPage(history.location.pathname));
              }
            }}
          >
            Back
          </Button>
          )}
          {nextDisabled !== null && (
          <Button
            disabled={nextDisabled}
            className={classes.nextButton}
            onClick={async () => {
              if (typeof nextClick === 'function') {
                await nextClick();
              } else {
                history.push(navigateToNextPage(history.location.pathname));
              }
            }}
            data-cy="onboardingNextButton"
          >
            {nextText}
          </Button>
          )}
        </div>
      </div>
    </section>
  );
};

OnboardingLayout.defaultProps = {
  backButton: false,
  dotNavigationLegend: -1,
  nextDisabled: null,
  nextClick: null,
  backClick: null,
  nextText: 'Next',
};

OnboardingLayout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  backButton: PropTypes.bool,
  nextDisabled: PropTypes.bool,
  dotNavigationLegend: PropTypes.number,
  nextClick: PropTypes.func,
  nextText: PropTypes.string,
  backClick: PropTypes.func,
};

export default compose(withStyles(styles), memo)(OnboardingLayout);
