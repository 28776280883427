import { asyncAction, action } from 'now-frontend-shared/utils/actions';

// async actions
export const getPreSignedUrls = asyncAction(
  'financingFiles/GET_PRE_SIGNED_URLS',
);

// sync action
export const reset = action('financingFiles/RESET');
export const setAWSData = action('financingFiles/SET_AWS_DATA');
export const removeAWSDataFile = action('financingFiles/REMOVE_AWS_DATA_FILE');
export const clearAWSData = action('financingFiles/CLEAR_AWS_DATA');
export const setUnloadedFilesExist = action('financingFiles/SET_UNLOADED_FILES_EXIST');
