import phoneInputStyles from 'now-frontend-shared/components/inputs/PhoneInput/styles';
import registrationStyles from '../Registration/styles';

export default {
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '34px',
    textAlign: 'center',
    letterSpacing: '0.25px',
    color: '#171721',
  },
  subTitle: {
    fontSize: '24px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  subTitleNew: {
    fontSize: '20px',
    fontWeight: '500',
    letterSpacing: '0.15px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: '20px',
  },
  text: {
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: '#4B6377',
    margin: '11px 0 25px 0',
  },
  buttonContainer: {
    marginTop: '20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  optionContainer: {
    display: 'flex',
    gap: '20px',
    marginTop: '23px',
    justifyContent: 'flex-end',
    width: '100%',
  },
  phoneInput: phoneInputStyles.phoneInput,
  ssnOrTinInput: registrationStyles.ssnOrTinInput,
  buttonContainerSingle: {
    marginTop: '20px',
    width: '100%',
    textAlign: 'right',
  },
  button: {
    backgroundColor: '#7890A6',
    color: '#ffffff',
  },
  backButton: {
    color: '#7890A6',
  },
  nextButton: {
    border: '1px solid rgba(120, 144, 166, 0.5)',
    boxSizing: 'border-box',
    borderRadius: '4px',
    padding: '8px 22px',
    color: '#7890A6',
    fontWeight: '500',
    fontSize: '15px',
    textTransform: 'uppercase',
    minWidth: '200px',
    height: '56px',
  },
  homeButton: {
    border: '1px solid rgba(178, 157, 121, 0.5)',
    color: '#B29D79',
    marginTop: '24px',
  },
  inputContainer: {
    width: '100%',
    marginTop: '30px',
  },
  fieldset: {
    width: '100%',
    border: '0',
  },
  dateContainer: {
    width: '100%',
    marginTop: '24px',
    marginRight: '220px',
  },
};
