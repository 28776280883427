import { signatureStyle as signature } from 'now-frontend-shared/styles/signature-styles';
import styles from '../../../../now-frontend-shared/styles/document-view-styles';

export default {
  button: styles.button,
  signature,
  downloadContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  download: styles.download,
};
