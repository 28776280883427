import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import styles from './styles';
import { persistCompanyUpdates, updateCompany } from 'store/actions/companyActions';
import { navigateToNextPage } from 'constants/registrationFlow';
import Onboarding from 'layouts/Onboarding';

function BeneficialOwnerView({ classes, company, authUser }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const saveCompany = async () => {
    const { BO25PercentOfCompanyOrMore } = company;

    const dataChanged
    = BO25PercentOfCompanyOrMore === authUser.company.BO25PercentOfCompanyOrMore;

    if (company.companyId && !dataChanged) {
      try {
        await new Promise((resolve, reject) => dispatch(persistCompanyUpdates({
          companyId: company.companyId,
          updates: {
            BO25PercentOfCompanyOrMore,
          },
          resolve,
          reject,
        })));
        history.push(navigateToNextPage(history.location.pathname, company.BO25PercentOfCompanyOrMore));
      } catch (err) {
      // eslint-disable-next-line no-console
        console.log(err);
      }
    } else {
      history.push(navigateToNextPage(history.location.pathname, company.BO25PercentOfCompanyOrMore));
    }
  };

  return (
    <Onboarding
      title="Beneficial Owner Detail"
      subTitle="Enter your details to proceed further"
      dotNavigationLegend={7}
      nextDisabled={company.BO25PercentOfCompanyOrMore === null}
      nextClick={saveCompany}
    >
      <Grid
        container
        direction="column"
        style={{ marginRight: 'auto', maxWidth: '840px' }}
      >
        <Typography variant="h5" component="div" className={classes.subTitle}>
          Do you have an ultimate beneficial owner that owns 25% of the company or more?
        </Typography>
        <div className={classes.optionContainer}>
          <Button
            className={
              company.BO25PercentOfCompanyOrMore === null || company.BO25PercentOfCompanyOrMore === false
                ? classes.button : classes.buttonSelected
            }
            data-cy="beneficialOwnerYes"
            variant="outlined"
            onClick={() => {
              dispatch(
                updateCompany({
                  value: true,
                  key: 'BO25PercentOfCompanyOrMore',
                }),
              );
            }}
          >
            Yes
          </Button>
          <Button
            className={
              company.BO25PercentOfCompanyOrMore === null || company.BO25PercentOfCompanyOrMore === true
                ? classes.button : classes.buttonSelected
            }
            data-cy="beneficialOwnerNo"
            variant="outlined"
            onClick={() => {
              dispatch(
                updateCompany({
                  value: false,
                  key: 'BO25PercentOfCompanyOrMore',
                }),
              );
            }}
          >
            No
          </Button>
        </div>
      </Grid>
    </Onboarding>
  );
}

BeneficialOwnerView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  company: PropTypes.objectOf(PropTypes.string).isRequired,
  authUser: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(
  connect(({ company, auth }) => ({
    authUser: auth.user,
    company,
  })),
  withStyles(styles),
)(BeneficialOwnerView);
