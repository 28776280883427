export const ContactUsInquiryTypes = {
  General: 'general',
  Technical: 'technical',
  RequestDemo: 'requestDemo',
};

export const ContactUsInquiryTypeTitles = {
  [ContactUsInquiryTypes.General]: 'General',
  [ContactUsInquiryTypes.Technical]: 'Technical',
  [ContactUsInquiryTypes.RequestDemo]: 'Request a Demo',
};
