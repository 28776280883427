export default {
  wrapper: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #ED6C02;',
    width: '100%',
    display: 'flex',
    gap: '15px',
    padding: '10px 18px',
    alignItems: 'flex-start',
    borderRadius: '4px',
    justifyContent: 'space-between',
    marginBottom: '25px',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: '#5F2B00',
  },
  icon: {
    width: '24px',
  },
  title: {
    color: '#5F2B00',
    fontWeight: 'bold',
  },
  description: {
    marginTop: '8',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  container: {
    display: 'flex',
    gap: '15px',
    alignItems: 'flex-start',
  },
};
