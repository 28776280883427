import { white, secondaryMain } from 'now-frontend-shared/themes/colors';

export default {
  button: {
    height: '52px',
    width: '220px',
    borderRadius: '8px',
    border: 'unset',
    outline: 'none',
    color: '#000',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '400',
    textAlign: 'center',
    textTransform: 'none',
    background: '#fff',
    boxShadow: 'unset',

    '&:hover': {
      backgroundColor: secondaryMain,
      color: white,
    },

    '@media screen and (max-width: 960px)': {
      marginTop: '20px',
    },
  },
  menuArrow: {
    marginRight: '24px',

    '@media screen and (max-width: 960px)': {
      marginRight: '10px',
    },
  },
  filename: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
