import React, { memo, useCallback, useMemo } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { useDispatch, connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';
import { compose } from 'redux';

// layouts
import LabelLayout from 'now-frontend-shared/components/inputs/layouts/LabelLayout';
import BottomSection from 'layouts/AuthSections/BottomSection';
import MainSection from 'now-frontend-shared/layouts/AuthSections/MainSection';
import HeadSection, { returnToPreviousPage } from 'now-frontend-shared/layouts/AuthSections/HeadSection';
import FormLayout from 'now-frontend-shared/layouts/FormLayout';

// components

// helpers
import { formattedPhoneToNumberString } from 'now-frontend-shared/utils/helpers';
import { warnAboutUnsavedForm } from 'now-frontend-shared/hoc/warnAboutUnsavedForm';
import { getCompanyName } from 'now-shared/helpers/company-helpers';

// validations
import { updateAccountValidate } from 'utils/validation/validations';

// constants

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import { updateAccount } from 'store/actions/authActions';
import styles from './styles';

// store
import { updateAccountFields } from './constants';
import LinkButton from 'now-frontend-shared/components/LinkButton';
import { COMPANY_DASHBOARD } from 'constants/registrationFlow';

const formName = 'updateAccount';

const UpdateAccount = ({
  classes, handleSubmit, formValues, user, history,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const onHandleSubmit = useCallback(({ company, phoneNumber, ...data }) => {
    if (!formValues) {
      return;
    }
    dispatch(updateAccount({
      ...data,
      phoneNumber: phoneNumber && formattedPhoneToNumberString(phoneNumber),
    }));
  }, [dispatch, formValues]);

  const onHandleClose = () => {
    returnToPreviousPage(location, history, COMPANY_DASHBOARD);
  };

  const disabledButton = useMemo(() => {
    const matchingPhoneNumber = (`${user.phoneNumber ?? ''}`)
      .replace(/\D/g, '')
      .replace(/^1/, '')
      .match(/(\d{3})(\d{3})(\d{4})/);
    const phoneNumber = matchingPhoneNumber?.length ? `+1 (${matchingPhoneNumber[1]}) ${matchingPhoneNumber[2]}-${matchingPhoneNumber[3]}` : '';

    return (
      formValues?.firstName === user.firstName
      && formValues?.lastName === user.lastName
      && formValues?.company === (getCompanyName(user.company) || undefined)
      && formValues?.department === user.department
      && formValues?.jobTitle === user.jobTitle
      && formValues?.email === user.email
      && formValues?.phoneNumber === phoneNumber
    );
  }, [formValues, user]);

  return (
    <FormLayout onSubmit={handleSubmit(onHandleSubmit)}>
      <HeadSection heading="Update Account Information" path={COMPANY_DASHBOARD} />

      <MainSection heading="Personal Details">
        {updateAccountFields.map(({ name, layoutProps, inputProps }) => (
          <LabelLayout key={name} name={name} {...layoutProps}>
            <Field name={name} {...inputProps} />
          </LabelLayout>
        ))}
      </MainSection>

      <BottomSection>
        <Grid container alignItems="center" className={classes.buttonContainer}>
          <div>
            Please contact support if you need to update your account information.
            <LinkButton
              label="Contact Us"
              buttonColor="clearGreen"
              path="/contact-us"
            />
          </div>
          {/* Commenting out submit and cancel buttons for now until we have a better update system in place */}
          {/* <Grid item className={classes.button}>
                  <Button label="Cancel" buttonColor="red" onClick={onHandleClose} />
                </Grid>

                <Grid item className={classes.button}>
                  <Button type="submit" label="Save" buttonColor="green" disabled={disabledButton} />
                </Grid> */}
        </Grid>
      </BottomSection>
    </FormLayout>
  );
};

UpdateAccount.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func,
};

const mapStateToProps = ({ auth: { user }, ...state }) => {
  const matchingPhoneNumber = (`${user.phoneNumber ?? ''}`)
    .replace(/\D/g, '')
    .replace(/^1/, '')
    .match(/(\d{3})(\d{3})(\d{4})/);
  const phoneNumber = matchingPhoneNumber?.length ? `+1 (${matchingPhoneNumber[1]}) ${matchingPhoneNumber[2]}-${matchingPhoneNumber[3]}` : '';

  return {
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      company: getCompanyName(user.company) || undefined,
      department: user.department,
      jobTitle: user.jobTitle,
      phoneNumber,
      email: user.email,
    },
    formValues: getFormValues('updateAccount')(state),
    user,
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: formName,
    validate: updateAccountValidate,
    enableReinitialize: true,
  }),
  warnAboutUnsavedForm,
  withStyles(styles),
  memo,
)(UpdateAccount);
