import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Onboarding from 'layouts/Onboarding';
import { hasCompanyInfoSaved, hasCompanyInfoToSubmit } from 'layouts/RegistrationRoute';
import { createNewCompany, updateCompany } from 'store/actions/companyActions';

import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import LockIcon from '@material-ui/icons/LockRounded';

import {
  validateNewEntityInformation,
} from 'now-shared/validation/new-entity-information';
import {
  formValidationHasErrors,
} from 'now-shared/validation/validation-rules';

import styles from './styles';
import { StyledTextField } from 'now-frontend-shared/components/inputs/StyledTextField/styledTextField';

import { getAllCompanies } from 'store/actions/companiesActions';
import { useHistory } from 'react-router-dom';
import { navigateToNextPage } from 'constants/registrationFlow';

const options = [
  { name: 'Corporation', value: 'corporation' },
  { name: 'General Partnership', value: 'general_partnership' },
  { name: 'Limited Liability Company', value: 'limited_liability_company' },
  {
    name: 'Limited Partnership or Limited Liability Partnership',
    value: 'limited_partnership_or_limited_liability_partnership',
  },
  { name: 'Sole Proprietorship', value: 'sole_proprietorship' },
];

function NewAccountView({ company, companies, user }) {
  const dispatch = useDispatch();
  const [touched, setTouched] = React.useState({});
  const [isSecretVisible, setIsSecretVisible] = React.useState({});
  const history = useHistory();
  const isCompanyNameSaved = user.company?.fullLegalCompanyName === company.fullLegalCompanyName;

  // TODO: [EDGECASE][BUG] if companies hasn't finished loading yet, this validation will pass
  // even if the company name is in the list that eventually loads.
  const errors = validateNewEntityInformation({ ...company, companies: isCompanyNameSaved ? [] : companies });

  const areBusinessFieldsValid = (
    !formValidationHasErrors(errors)
  );

  const setTouchedDelay = 0;
  const setTouchedDelayed = (fieldName, newValue) => {
    setTimeout(() => setTouched(prev => ({
      ...prev,
      [fieldName]: newValue,
    })), setTouchedDelay);
  };

  const setIsSecretVisibleDelayed = (fieldName, newValue) => {
    setTimeout(() => setIsSecretVisible(prev => ({
      ...prev,
      [fieldName]: newValue,
    })), setTouchedDelay);
  };

  const businessFields = [
    {
      label: 'Full Legal Name of Company',
      value: 'fullLegalCompanyName',
      dataCy: 'companyNameField',
    },
    {
      label: 'Operating Name (subsidiary/DBA) optional',
      value: 'operatingName',
      dataCy: 'operatingNameField',
    },
  ];

  useEffect(() => {
    /* TODO: [SECURITY]
      Remove this call and create a call specific call for checking a company's name against existing companies.
    */
    dispatch(getAllCompanies());
    if (typeof company.role === 'string') {
      dispatch(updateCompany({ key: 'role', value: options.find(role => role.value === company.role) }));
    }
  }, [dispatch]);

  const createFields = (list, autoFocus = false) => (
    <>
      {list.map(({
        label,
        value,
        isSecret,
        dataCy,
      }, i) => {
        const error = touched[value] && errors[value];
        return (
          <StyledTextField
            value={company[value] || ''}
            key={value}
            type={(!isSecret || isSecretVisible[value]) ? 'text' : 'password'}
            variant="outlined"
            onChange={e => {
              dispatch(updateCompany({ key: value, value: e.target.value || null }));
            }}
            onFocus={() => {
              setTouchedDelayed(value, false);
              if (isSecret) {
                setIsSecretVisibleDelayed(value, true);
              }
            }}
            onBlur={() => {
              setTouchedDelayed(value, true);
              if (isSecret) {
                setIsSecretVisibleDelayed(value, false);
              }
            }}
            label={label}
            data-cy={dataCy}
            error={!!error}
            helperText={error || '\u00a0'}
            placeholder={label}
            fullWidth
            margin="normal"
            {...isSecret && {
              InputProps: {
                endAdornment: (
                  <LockIcon />
                ),
              },
            }}
            autoFocus={autoFocus && i === 0}
          />
        );
      })}
    </>
  );

  const saveCompany = async () => {
    if (!hasCompanyInfoSaved(user) && !company.companyId && hasCompanyInfoToSubmit(company)) {
      try {
        await new Promise((resolve, reject) => dispatch(createNewCompany({
          resolve,
          reject,
        })));
        history.push(navigateToNextPage(history.location.pathname));
      } catch (err) {
      // eslint-disable-next-line no-console
        console.log(err);
      }
    } else {
      history.push(navigateToNextPage(history.location.pathname));
    }
  };

  return (
    <Onboarding
      title="Company Details"
      subTitle="Enter your details to proceed further"
      dotNavigationLegend={3}
      nextDisabled={!areBusinessFieldsValid}
      nextClick={saveCompany}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ marginRight: 'auto', maxWidth: '840px' }}
      >
        {createFields(businessFields, true)}
        <Autocomplete
          margin="normal"
          fullWidth
          value={company.role}
          onChange={(_, newValue) => {
            dispatch(updateCompany({ value: newValue, key: 'role' }));
          }}
          onFocus={() => setTouchedDelayed('role', false)}
          onBlur={() => setTouchedDelayed('role', true)}
          id="entity-select"
          options={options}
          style={{
            marginTop: '16px',
            marginBottom: '8px',
          }}
          getOptionLabel={option => option.name || ''}
          renderInput={params => {
            const error = touched.role && errors.role;
            return (
              <StyledTextField
                {...params}
                label="Entity Type"
                data-cy="entityTypeField"
                variant="outlined"
                error={!!error}
                helperText={error || '\u00a0'}
              />
            );
          }}
        />
      </Grid>
    </Onboarding>
  );
}

NewAccountView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  company: PropTypes.objectOf(PropTypes.any).isRequired,
  companies: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    company: PropTypes.shape({
      fullLegalCompanyName: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default compose(
  connect(({ company, companies, auth }) => ({
    company,
    companies: companies.companies,
    user: auth.user,
  })),
  withStyles(styles),
)(NewAccountView);
